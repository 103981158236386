/* eslint-disable */
import { plugin } from 'app/lib/common';

export default plugin('clickable-cell')(
  class {
    constructor() {
      $('body').on('click', '.clickable-cell', function (e) {
        // On click .clickable-cell,
        // look for an href that is a descendent of this element.
        // If not found, find parent tr then look for first HREF in the row
        // so that whole row of table can be clickable.
        // td.clickable-cell is used instead of tr.clickable-row because
        // there may be cells with buttons.  This way, you just leave off the class on those cells.
        let href = $(this).find('a:first').attr('href');
        if (!href) {
          href = $(this).closest('tr').find('a:first').attr('href');
        }

        if (href) {
          if (e.metaKey) {
            window.open(href, '_blank');
            e.preventDefault();
          } else {
            window.document.location = href;
          }
        }
      });
    }
  },
);
