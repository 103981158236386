import React from 'react';
import Wysiwyg from 'app/components/Wysiwyg';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

export const RT_WYSIWYG_TYPE = 'wysiwyg';
export const RT_CANVAS_TEXT_TYPE = 'canvas_text';
export const RT_HTML_TYPE = 'html';

const richTextTypes = [
  { type: RT_WYSIWYG_TYPE, name: 'Rich Text Editor' },
  { type: RT_CANVAS_TEXT_TYPE, name: 'Canvas Text' },
  { type: RT_HTML_TYPE, name: 'HTML' },
];

const SelectRichTextType = ({ onChange, value }) => {
  return (
    <select
      className="float-right form-control-sm w-25"
      value={value}
      onChange={onChange}
    >
      {richTextTypes.map(i => (
        <option key={i.type} value={i.type}>
          {i.name}
        </option>
      ))}
    </select>
  );
};

const RichTextEditor = props => {
  const {
    id,
    label,
    value,
    placeholder,
    onValueChange,
    onTypeChange,
    toolbarOptions,
    disableTypeSelection,
    input,
  } = props;
  const type = props.type || RT_WYSIWYG_TYPE;

  return (
    <div className="form-field m-2">
      {label && <label>{label}</label>}

      {!disableTypeSelection && (
        <SelectRichTextType
          value={type}
          onChange={e => onTypeChange(e.target.value)}
        />
      )}
      <div className="clearfix" />

      {type === RT_WYSIWYG_TYPE && (
        <Wysiwyg
          id={id}
          value={value}
          toolbarOptions={toolbarOptions}
          placeholder={placeholder}
          onChange={e => onValueChange && onValueChange(e)}
          {...input}
        />
      )}

      {type === RT_CANVAS_TEXT_TYPE && (
        <textarea
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={e => onValueChange && onValueChange(e.target.value)}
          className="form-control"
          style={RT_HTML_TYPE && { fontFamily: 'monospace' }}
          {...input}
        />
      )}

      {type === RT_HTML_TYPE && (
        <CodeMirror
          id={id}
          value={value}
          extensions={html()}
          height="300px"
          placeholder={placeholder}
          onChange={onValueChange}
          {...input}
        />
      )}
    </div>
  );
};

export default RichTextEditor;
