import { getCSRFToken } from 'app/lib/utils/rest';

// Helpers
const parseJSON = r => r.json();
const toJson = o => JSON.stringify(o);

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function request(url, options) {
  const additionalOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'include',
  };

  return fetch(url, { ...options, ...additionalOptions })
    .then(checkStatus)
    .then(parseJSON);
}

export function post(url, payload) {
  return request(url, {
    method: 'POST',
    body: toJson(payload),
  });
}

export function patch(url, payload) {
  return request(url, {
    method: 'PATCH',
    body: toJson(payload),
  });
}

export function put(url, payload) {
  return request(url, {
    method: 'PUT',
    body: toJson(payload),
  });
}

export function get(url) {
  return request(url, {
    method: 'GET',
  });
}

export function uploadToS3(
  presignedUrl,
  file,
  { onLoad, onProgress, onError } = {},
) {
  const xhr = new XMLHttpRequest();

  if (onProgress) xhr.upload.onprogress = onProgress;

  const promise = new Promise((res, rej) => {
    const onLoadEvent = () => {
      xhr.removeEventListener('load', onLoadEvent);
      if (onLoad) onLoad(xhr);
      if (xhr.status !== 200) return rej(xhr);
      res(xhr);
    };

    const onErrorEvent = () => {
      xhr.removeEventListener('error', onErrorEvent);
      if (onError) onError(xhr);
      rej();
    };

    xhr.addEventListener('load', onLoadEvent);
    xhr.addEventListener('error', onErrorEvent);
  });

  xhr.open('PUT', presignedUrl);
  xhr.send(file);
  return promise;
}
