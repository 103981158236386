import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notify } from 'app/lib/common';

const ClickToCopy = ({ linkText, link }) => {
  return (
    <CopyToClipboard text={link} onCopy={() => notify('URL has been copied.')}>
      <span className="d-flex align-items-center bold p-0">
        <i className="material-icons mr-2 btn-link">insert_link</i>
        <span className="break-all btn-link">{linkText}</span>
      </span>
    </CopyToClipboard>
  );
};

export default ClickToCopy;
