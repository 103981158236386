import React, { Component } from 'react';
import startCase from 'lodash/startCase';
import NewStepCards from 'app/components/admin/NewStepCards';
import { getCSRFToken } from 'app/lib/utils/rest';

export default class NewStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isSubmitting: false, stepType: '', name: '' };
  }

  handleClick = stepType => {
    this.setState({ isSubmitting: true, stepType }, this.submit);
  };

  handleChange = event => {
    this.setState({ name: event.target.value });
  };

  submit = () => {
    this.formRef.submit();
  };

  /* eslint-disable max-len */
  render = () => {
    const { isSubmitting, name, stepType } = this.state;
    const { action, translations, authorizations } = this.props;

    return (
      <form
        ref={formRef => {
          this.formRef = formRef;
        }}
        action={action}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="utf8" value="✓" />
        <input type="hidden" value={getCSRFToken()} name="authenticity_token" />
        <input type="hidden" value={stepType} name="template_type" />

        <div className="card card-body mb-3">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              onChange={this.handleChange}
              type="text"
              value={name}
              className="form-control"
              placeholder="A descriptive name"
              name="name"
            />
          </div>
        </div>

        <div className="card card-body">
          <div className="form-group">
            <label className="mb-0">{`${startCase(
              translations.assessment.one,
            )} Type`}</label>
            <p className="text-muted">
              {`Choose one of the ${translations.assessment.one} types to get started building a custom ${translations.assessment.one}`}
            </p>
          </div>
          <NewStepCards
            onClick={this.handleClick}
            disabled={isSubmitting}
            newAssessment
            translations={translations}
            authorizations={authorizations}
          />
        </div>
      </form>
    );
  };
  /* eslint-enable max-len */
}
