import React, { Component } from 'react';
import Select from 'react-select';
import { get } from 'app/common/api';
import debounce from 'lodash/debounce';

export default class MoveAdminToOrganization extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearch = debounce(this.handleSearch, 250);
    this.handleChange = this.handleChange.bind(this);
    this.formatSearchResultItem = this.formatSearchResultItem.bind(this);

    this.state = {
      organizations: [],
      isLoading: false,
      selectedOrganization: props.currentOrganization,
      admin_id: props.admin_id,
    };
  }

  handleChange({ id }) {
    const { admin_id } = this.state;
    this.setState({ isLoading: true });

    get(`/admin/audit_admins/move?id=${admin_id}&organization_id=${id}`).then(
      newOrganization => {
        this.setState({
          isLoading: false,
          selectedOrganization: newOrganization,
        });
        window.location.reload();
      },
    );
  }

  handleSearch(searchTerm) {
    this.setState({ isLoading: true });
    get(`/admin/organization/search?term=${searchTerm}`).then(organizations => {
      this.setState({ organizations, isLoading: false });
    });
  }

  formatSearchResultItem({ id, name }) {
    return (
      <div>
        <span>
          <span className="text-muted small mr-1">{id}</span>
          {name}
        </span>
      </div>
    );
  }

  render() {
    const { organizations, isLoading, selectedOrganization } = this.state;

    return (
      <Select
        isLoading={isLoading}
        className="basic-search"
        options={organizations}
        placeholder="🏢 Filter by organization..."
        onInputChange={this.handleSearch}
        onChange={this.handleChange}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        formatOptionLabel={this.formatSearchResultItem}
        value={selectedOrganization}
      />
    );
  }
}
