import React, { Component } from 'react';
import Select from 'react-select';
import range from 'lodash/range';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import { FixedSizeList } from 'react-window';
import { getCSRFToken } from 'app/lib/utils/rest';

const EMPTY_TAXONOMY_ASSESSMENTS_COUNT = 20;

const rankingOptions = () => {
  const rankings = range(1, 101);
  return rankings.map(ranking => ({ value: ranking, label: ranking }));
};

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const height = 35;
  const initialScrollOffset = options.indexOf(value) * height;
  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialScrollOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};

const TaxonomyAssessment = ({ index, taxonomyOptions, onChange }) => (
  <li className="list-group-item d-flex align-items-center justify-content-between row">
    <div className="col-md-2">{`Entry #${index}`}</div>
    <div className="col-md-8">
      <Select
        components={{ MenuList }}
        options={taxonomyOptions}
        placeholder="Taxonomy..."
        onChange={val => onChange({ index, ...val, ...{ type: 'taxonomy' } })}
      />
    </div>
    <div className="col-md-2">
      <Select
        name="ranking"
        options={rankingOptions()}
        placeholder="Ranking..."
        onChange={val => onChange({ index, ...val, ...{ type: 'ranking' } })}
      />
    </div>
  </li>
);

const TaxonomyAssessments = ({ taxonomyOptions, onChange }) => (
  <ul className="container list-group list-group-flush">
    {range(0, EMPTY_TAXONOMY_ASSESSMENTS_COUNT).map(index => (
      <TaxonomyAssessment
        index={index}
        taxonomyOptions={taxonomyOptions}
        key={index}
        onChange={onChange}
      />
    ))}
  </ul>
);

export default class TaxonomyAssessmentsForm extends Component {
  constructor(props) {
    super(props);
    const emptyTaxonomyAssessments = range(0, EMPTY_TAXONOMY_ASSESSMENTS_COUNT);
    this.state = {
      taxonomyAssessments: emptyTaxonomyAssessments.map(index => ({
        index,
        taxonomy_id: undefined,
        ranking: undefined,
      })),
      formErrors: null,
    };
  }

  handleSubmit = () => {
    const { postUrl, redirectUrl } = this.props;
    const { taxonomyAssessments } = this.state;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        taxonomy_assessments: filter(
          taxonomyAssessments,
          o => o.taxonomy_id && o.ranking,
        ),
      }),
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      credentials: 'same-origin',
    };

    return fetch(postUrl, options).then(() =>
      window.location.assign(redirectUrl),
    );
  };

  validateEnteredData = () => {
    const { taxonomyAssessments } = this.state;
    const invalidEntries = filter(
      taxonomyAssessments,
      o => (!o.taxonomy_id && o.ranking) || (o.taxonomy_id && !o.ranking),
    );
    if (invalidEntries.length > 0) {
      this.setState({
        formErrors: `These entries are invalid: ${invalidEntries
          .map(invalidEntry => invalidEntry.index)
          .join(', ')}. (Both taxonomy and ranking are required.)`,
      });
    } else {
      this.setState({ formErrors: null });
    }
  };

  onChange = option => {
    const { index, value, type } = option;
    const { taxonomyAssessments } = this.state;
    const clonedTaxonomyAssessments = cloneDeep(taxonomyAssessments);
    const taxonomyAssessment = clonedTaxonomyAssessments.find(
      taxonomyAssessment => taxonomyAssessment.index === index,
    );
    type === 'taxonomy'
      ? (taxonomyAssessment.taxonomy_id = value)
      : (taxonomyAssessment.ranking = value);

    this.setState(
      { taxonomyAssessments: clonedTaxonomyAssessments },
      this.validateEnteredData,
    );
  };

  render() {
    const { taxonomyOptions } = this.props;
    const { formErrors } = this.state;
    return (
      <form>
        <TaxonomyAssessments
          taxonomyOptions={taxonomyOptions}
          onChange={this.onChange}
        />
        <div className="d-flex align-items-center justify-content-start p-3">
          <button
            type="button"
            className="btn btn-primary btn-wide"
            disabled={formErrors}
            onClick={this.handleSubmit}
          >
            Save
          </button>
          <div className="text-danger ml-5">{formErrors}</div>
        </div>
      </form>
    );
  }
}
