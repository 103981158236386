import React, { Component } from 'react';
import { post } from 'app/common/api';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export default class CloneAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMigrating: false,
      clonedName: null,
      showModal: false,
    };
  }

  handleConfirmButtonPressed = () => {
    this.setState({ isMigrating: true }, this.cloneAssessment);
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  cloneAssessment = () => {
    const payload = {
      id: this.props.assessment_id,
    };

    return post('/superadmin/clone_assessment', payload).then(name =>
      this.setState({ clonedName: name.clonedName }),
    );
  };

  render() {
    const { isMigrating, clonedName, showModal } = this.state;
    const { originalName } = this.props;

    return (
      <div>
        <Button color="secondary" onClick={this.showModal}>
          Clone
        </Button>
        <Modal isOpen={showModal} className="clone-assessment-modal">
          <ModalHeader>Clone Assessment</ModalHeader>
          <ModalBody>
            <p className="text-center">
              The following action will clone the &quot;{originalName}&quot;
              module.
            </p>

            {isMigrating && clonedName && (
              <div className="text-center">
                <p>
                  Your module is cloning in the background and should be
                  complete shortly. It is safe to close this modal. Please wait
                  a minute or two and then find the cloned module in the modules
                  list under the name:
                </p>
                <p> {clonedName} </p>
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isMigrating}
              onClick={this.handleConfirmButtonPressed}
              color="danger"
            >
              Clone
            </Button>
            <Button color="primary" onClick={this.closeModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
