import mockRaven from 'test/helpers/mockRaven';
import get from 'lodash/get';
import _set from 'lodash/set';

export const getAppInstance = () => window.iaWeb;

export const getRaven = () => window.Raven || mockRaven();

export const getUser = () => get(window, 'iaweb_tracking.user');

export const getPageLocale = () => get(window, 'iaweb_tracking.page_locale');

export const getDsn = () => get(window, 'iaweb_tracking.dsn');

export const getUserType = () => get(window, 'iaweb_tracking.user_type');

const APP_DATA_PATH = 'iaWeb.apps';

export const getGlobalAppData = (app, dataPath, defaultValue = null) => {
  return get(window, `${APP_DATA_PATH}.${app}.${dataPath}`, defaultValue);
};

export const setGlobalAppData = (app, dataPath, value) => {
  _set(window, `${APP_DATA_PATH}.${app}.${dataPath}`, value);
};
