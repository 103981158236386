import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import toPairs from 'lodash/toPairs';

const getBaseUrl = () =>
  `https://res.cloudinary.com/${window.app.get(
    'CLOUDINARY_CLOUD_NAME',
  )}/image/upload/`;

export function transform(publicId, options = {}) {
  const paramsMap = {
    width: 'w_',
    height: 'h_',
    angle: 'a_',
    crop: 'c_',
    format: 'f_',
    page: 'pg_',
  };

  let transformations = '';
  const optionPairs = toPairs(omitBy(options, isNil));
  forEach(optionPairs, (arr, i) => {
    const [key, val] = arr;
    transformations += paramsMap[key];
    transformations += val;
    if (i < optionPairs.length - 1) transformations += ',';
  });

  return `${getBaseUrl() + transformations}/${publicId}`;
}

export function url(publicId) {
  return getBaseUrl() + publicId;
}
