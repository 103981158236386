// https://github.com/google/material-design-icons/blob/3.0.1/iconfont/codepoints
// account_box info: https://material.io/icons/#ic_account_box
// account_box json: https://material.io/icons/data/ic_account_box.json
const materialIconMapping = {
  account_box: '&#xE851;',
  add: '&#xE145;',
  add_circle_outline: '&#xE148;',
  arrow_downward: '&#xE5DB;',
  arrow_upward: '&#xE5D8;',
  assignment: '&#xE85D;',
  calendar_today: '&#xE935',
  check: '&#xE5CA;',
  check_box: '&#xE834;',
  check_box_outline_blank: '&#xE835;',
  check_circle: '&#xE86C;',
  close: '&#xE5CD;',
  delete: '&#xE872;',
  done: '&#xE876;',
  down_arrowhead: '&#x2304;',
  drag_handle: '&#xE25D;',
  error_outline: '&#xE001;',
  expand_more: '&#xE5CF;',
  fiber_manual_record: '&#xE061;',
  file_upload: '&#xE2C6;',
  highlight_off: '&#xE888;',
  history: '&#xE889;',
  hourglass_empty: '&#xE88B;',
  insert_drive_file: '&#xE24D;',
  keyboard_arrow_left: '&#xE314;',
  keyboard_arrow_right: '&#xE315;',
  link: '&#xE157;',
  mic: '&#xE029;',
  navigate_before: '&#xE408',
  navigate_next: '&#xE409',
  open_in_new: '&#xE89E;',
  pause: '&#xE034;',
  perm_device_information: '&#xE8A5;',
  person: '&#xE7FD;',
  photo_camera: '&#xE412;',
  play_arrow: '&#xE037;',
  play_circle_filled: '&#xE038;',
  public: '&#xE80B;',
  radio_button_checked: '&#xE837;',
  radio_button_unchecked: '&#xE836;',
  refresh: '&#xE5D5;',
  stop: '&#xE047;',
  swap_vert: '&#xE8D5;',
  tag_faces: '&#xE420;',
  videocam: '&#xE04B;',
  warning: '&#xE002;',
  replay: '&#xE042;',
  replay_5: '&#xE05B;',
  replay_10: '&#xE059;',
  forward_5: '&#xE058;',
  forward_10: '&#xE056;',
  zoom_in: '&#xE8FF',
};

export function getIconCode(iconName) {
  const iconCode = materialIconMapping[iconName];

  if (!iconCode) {
    // eslint-disable-next-line no-console
    console.warn(`materialIconMapping missing for ${iconName}.`);
  }

  return iconCode || null;
}

export default materialIconMapping;
