import React, { Component } from 'react';
import startCase from 'lodash/startCase';

class SurveyQuestion extends Component {
  constructor(props) {
    super(props);
    const { question } = this.props;
    this.state = {
      type: question ? question.type : 'stars',
    };
  }

  handleTypeChange = e => {
    this.setState({ type: e.target.value });
  };

  render() {
    const {
      formPrefix,
      id,
      surveyQuestionBaseUrl,
      assignedId,
      types,
      languages,
      question,
    } = this.props;
    const { type } = this.state;
    return (
      <div className="card p-3 my-3">
        {question && question.id && (
          <input type="hidden" name={`${formPrefix}[id]`} value={question.id} />
        )}

        <div className="form-group">
          <label htmlFor="prompt">Question Prompt</label>
          <textarea
            className="form-control"
            id={`prompt_${assignedId}`}
            name={`${formPrefix}[prompt]`}
            row="4"
            defaultValue={question ? question.prompt : ''}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-sm">
            <label htmlFor="type">Question Type</label>
            <select
              className="form-control"
              id={`type_${assignedId}`}
              name={`${formPrefix}[type]`}
              onChange={this.handleTypeChange}
              defaultValue={question ? question.type : 'stars'}
            >
              {types.map(type => (
                <option value={type[1]} key={type[1]}>
                  {startCase(type[0])}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-sm">
            <label htmlFor="language">Language</label>
            <select
              className="form-control"
              id={`language_${assignedId}`}
              name={`${formPrefix}[language]`}
              defaultValue={
                question && question.language ? question.language : 'US'
              }
            >
              {languages.map(language => (
                <option value={language[1]} key={language[1]}>
                  {language[0]}
                </option>
              ))}
            </select>
          </div>
        </div>
        {type === 'yes_or_no' && (
          <div className="form-group">
            <label htmlFor="prompt_helper">Question Prompt Helper</label>
            <textarea
              className="form-control"
              id={`prompt_helper_${assignedId}`}
              name={`${formPrefix}[prompt_helper]`}
              row="2"
              defaultValue={question ? question.prompt_helper : ''}
            />
          </div>
        )}

        {id && (
          <div className="">
            <a
              href={`${surveyQuestionBaseUrl}${id}`}
              className="d-flex align-items-center text-danger"
              data-confirm="Are you sure?"
              data-method="delete"
            >
              <i className="material-icons">delete_forever</i>
              Delete this question
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default SurveyQuestion;
