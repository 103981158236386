import autosize from 'autosize';
import { plugin } from 'app/lib/common';

export default plugin('autosize')(
  class {
    constructor() {
      autosize(document.querySelectorAll('.autosize'));
    }
  },
);
