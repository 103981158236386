class MockRaven {
  captureMessage(...args) {
    // eslint-disable-next-line no-console
    console.error('mockRaven.captureMessage', ...args);
  }

  captureExecption(...args) {
    // eslint-disable-next-line no-console
    console.error('mockRaven.captureExecption', ...args);
  }
}

export default () => new MockRaven();
