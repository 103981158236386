import React from 'react';
import { url } from 'app/lib/utils/cloudinary';
import Loader from 'app/components/candidate/Loader';
import { Flex } from '@indeed/ifl-components';

export default class ImageMapImage extends React.Component {
  static defaultProps = {
    children: () => {},
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      imageReductionPercentage: 1,
      loading: true,
      originalImageWidth: 0,
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleLoad = url => {
    this.getOriginalImageSize(url, width =>
      this.setState({ originalImageWidth: width, loading: false }, () =>
        this.detectImageSizing(),
      ),
    );
  };

  handleClick = e => {
    this.props.onClick(e, this.state.imageReductionPercentage);
  };

  handleResize() {
    this.detectImageSizing();
  }

  // we have to do this in an onload because if we used a ref, its value would change on window resize,
  // which would throw off proportion calculation
  getOriginalImageSize(url, callback) {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width);
    };
  }

  detectImageSizing = () => {
    const currentImageWidth = this.image.getBoundingClientRect().width;
    const imageReductionPercentage =
      currentImageWidth / this.state.originalImageWidth;
    this.setState({ imageReductionPercentage });
  };

  isSelected = hotspot => {
    const { value } = this.props.input;
    return value.value === hotspot.sequence;
  };

  getCoordinates = (x, y, width = 0, height = 0) => {
    return {
      top: y * this.state.imageReductionPercentage,
      left: x * this.state.imageReductionPercentage,
      width: width * this.state.imageReductionPercentage,
      height: height * this.state.imageReductionPercentage,
    };
  };

  render() {
    const { image, children } = this.props;
    const src = image.url || url(image.public_id);

    return (
      <Flex
        onClick={this.handleClick}
        sx={{
          position: 'relative',
          display: 'inline-block',
          maxWidth: '100%',
          backgroundSize: 'contain',
          '[data-testid="main-image"]': {
            visibility: this.state.loading ? 'hidden' : 'visible',
            maxWidth: '100%',
          },
        }}
        data-testid="image-map-image"
      >
        {children(this.getCoordinates, this.state.imageReductionPercentage)}

        <Flex
          sx={{
            position: 'absolute',
            visibility: 'hidden',
            zIndex: '-1',
            '>img': { maxWidth: '100%' },
          }}
        >
          <img src={src} onLoad={() => this.handleLoad(src)} alt="" />
        </Flex>
        {this.state.loading && (
          <Flex sx={{ p: 5, justifyContent: 'center' }}>
            <Loader />
          </Flex>
        )}
        <img
          data-testid="main-image"
          src={src}
          alt={image.alternate_text}
          ref={elem => {
            this.image = elem;
          }}
        />
      </Flex>
    );
  }
}
