import { plugin } from 'app/lib/common';

export default plugin('bootstrap')(
  class {
    constructor() {
      $('[data-toggle="tooltip"]').tooltip();

      $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        placement: 'bottom',
        container: 'body',
        html: true,
      });

      // SHow modals on page load if the show class is present
      $('.modal.show').modal('show');
    }
  },
);
