import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

const cloudinaryUrl = publicId =>
  `https://res.cloudinary.com/${window.app.get(
    'CLOUDINARY_CLOUD_NAME',
  )}/image/upload/${publicId}`;

const GalleryImage = ({ image }) => {
  const src = image.url ? image.url : cloudinaryUrl(image.publicId);

  return (
    <img
      id={image.publicId || image.url}
      className="align-self-center w-100 mt-1 mb-1"
      src={src}
    />
  );
};

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);
    const selectedImage = props.images[0];
    this.state = {
      selectedImage,
      showModal: false,
    };
  }

  handleNavClick = image => {
    this.setState({ selectedImage: image });
  };

  handleImageClick = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { images } = this.props;
    const { selectedImage, showModal } = this.state;

    return (
      <div className="image-gallery text-center text-sm-left">
        <Row className="border rounded m-0">
          <Col xs="5" md="4" className="px-0 d-none d-sm-inline-block">
            {images.map((image, i) => {
              return (
                <button
                  key={`${image.publicId}${image.url}`}
                  // eslint-disable-next-line max-len
                  className={`nav-button bold py-3 rounded-0 border-bottom btn btn-link btn-wide ${
                    selectedImage === image ? 'text-dark' : 'border-right'
                  }`}
                  onClick={() => this.handleNavClick(image)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pr-5">Example {i + 1}</div>
                    <i className="material-icons">keyboard_arrow_right</i>
                  </div>
                </button>
              );
            })}
          </Col>
          <Col className="position-relative">
            {selectedImage && (
              <button className="btn rounded-0" onClick={this.handleImageClick}>
                <div className="d-flex">
                  <GalleryImage image={selectedImage} />
                  <span className="modal-btn position-absolute text-light bg-dark rounded">
                    <i className="material-icons text-muted-light small">
                      open_in_new
                    </i>
                  </span>
                </div>
              </button>
            )}
          </Col>
        </Row>
        <div className="mt-2 d-inline-block d-sm-none">
          {images.map(image => {
            return (
              <button
                key={`${image.publicId}${image.url}`}
                className={`btn p-1 mr-2 rounded-circle ${
                  selectedImage === image ? 'btn-dark' : 'inactive-nav-btn'
                }`}
                onClick={() => this.handleNavClick(image)}
              />
            );
          })}
        </div>

        <Modal isOpen={showModal} size="lg">
          <ModalHeader toggle={this.handleImageClick}>
            Example Question
          </ModalHeader>
          <ModalBody>
            <GalleryImage image={selectedImage} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
