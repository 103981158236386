import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCSRFToken } from 'app/lib/utils/rest';
import CandidateInvite from './CandidateInvite';

export default class CandidateInviteList extends Component {
  constructor(props) {
    super(props);
    this.nextAvailableInviteId = 0;
    this.state = {
      invites: [this.createInvite()],
      firstInvalidInvite: null,
      submitted: false,
    };
    this.submitButtonRef = React.createRef();
    this.lastCandidateCreated = null;
  }

  onCandidateInviteChanged = (index, isValid, message) => {
    this.setState(prevState => {
      const updatedInvite = this.createInvite(
        isValid,
        message,
        prevState.invites[index].id,
      );
      const updateInvites = prevState.invites.slice();
      updateInvites[index] = updatedInvite;
      return { invites: updateInvites };
    });
  };

  addCandidateInvite = () => {
    if (this.state.invites.length < this.props.maxInviteCount) {
      this.setState(prevState => ({
        invites: [...prevState.invites, this.createInvite()],
      }));
    }
  };

  handleSubmit = event => {
    const { invites } = this.state;
    const invalidInvite = invites.find(invite => !invite.isValid);
    if (invalidInvite) {
      event.preventDefault();
      this.setState({
        firstInvalidInvite: this.createInvite(
          invalidInvite.isValid,
          invalidInvite.message,
          invalidInvite.id,
        ),
      });
    } else {
      this.setState({
        firstInvalidInvite: null,
        submitted: true,
      });
    }
  };

  createInvite(isValid = true, message = '', id = null) {
    let inviteId = id;
    if (inviteId === null) {
      inviteId = this.nextAvailableInviteId;
      this.nextAvailableInviteId += 1;
    }
    return { isValid, message, id: inviteId };
  }

  render() {
    const { invites, firstInvalidInvite, submitted } = this.state;
    const { maxInviteCount, roleSlug, formAction, formPrefix } = this.props;

    const inviteElements = invites.map((invite, index) => {
      return (
        <CandidateInvite
          id={invite.id}
          onChanged={this.onCandidateInviteChanged}
          index={index}
          formPrefix={formPrefix}
          firstInvalidInvite={firstInvalidInvite}
          key={invite.id}
        />
      );
    });

    return (
      <div>
        <form
          action={formAction}
          acceptCharset="UTF-8"
          method="post"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="utf8" value="✓" />
          <input
            type="hidden"
            value={getCSRFToken()}
            name="authenticity_token"
          />
          <div className="mx-4">
            {firstInvalidInvite && (
              <div className="alert alert-danger">
                <strong>Error!</strong> {firstInvalidInvite.message}
              </div>
            )}
            <input type="hidden" name="role_id" value={roleSlug} />
            <h4 className="text-dark font-weight-bold mb-0">
              Who do you want to send this to?
            </h4>
            <div className="pre-scrollable">{inviteElements}</div>
            {invites.length < maxInviteCount && (
              <div className="w-100 text-right">
                <button
                  type="button"
                  className="btn btn-link py-0 mb-3"
                  id="inviteAnotherCandidateLink"
                  onClick={this.addCandidateInvite}
                >
                  <i className="material-icons">add_circle_outline</i> Add
                  another candidate
                </button>
              </div>
            )}
          </div>
          <div className="card-footer text-right border-top-0">
            <button
              type="button"
              className="btn btn-outline-primary btn-wide border-2 bold"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              type="submit"
              name="commit"
              className="btn btn-primary btn-wide border-2 bold ml-3 p-2"
              ref={this.submitButtonRef}
              disabled={submitted}
            >
              Send Assessment
            </button>
          </div>
        </form>
      </div>
    );
  }
}

CandidateInviteList.propTypes = {
  maxInviteCount: PropTypes.number.isRequired,
  roleSlug: PropTypes.string.isRequired,
  formAction: PropTypes.string.isRequired,
  formPrefix: PropTypes.string.isRequired,
};
