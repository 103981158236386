import React, { Component } from 'react';
import * as API from 'app/common/api';
import classNames from 'classnames';
import titleCase from 'title-case';

export default class TaxonomyAutocompleteField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.queryStrValue || '', // Prop as init state is okay: we never want to reset to this
      selectedTaxonomyIndex: 0,
      taxonomies: [],
    };
  }

  onSelectFor(e, title) {
    e.preventDefault();
    this.completeTitle(title);
  }

  handleKeyDown = e => {
    const { selectedTaxonomyIndex, taxonomies } = this.state;

    if (!taxonomies.length) return;

    if (e.key === 'ArrowDown') {
      const newIndex =
        selectedTaxonomyIndex + 1 >= taxonomies.length
          ? 0
          : selectedTaxonomyIndex + 1;
      this.setState({ selectedTaxonomyIndex: newIndex });
    } else if (e.key === 'ArrowUp') {
      const newIndex =
        selectedTaxonomyIndex - 1 < 0
          ? taxonomies.length - 1
          : selectedTaxonomyIndex - 1;
      this.setState({ selectedTaxonomyIndex: newIndex });
    } else if (e.key === 'Enter') {
      e.preventDefault();
      this.completeTitle(taxonomies[selectedTaxonomyIndex].title);
    }
  };

  handleChange = e => {
    const { value } = e.target;

    if (value.length > 2) {
      this.setState({ value });
      this.fetchTaxonomies(value);
    } else {
      this.setState({ value, taxonomies: [] });
    }
  };

  fetchTaxonomies(value) {
    return API.get(`/admin/taxonomies?autocomplete=${value}`).then(data => {
      if (data) this.setState({ taxonomies: data, selectedTaxonomyIndex: 0 });
    });
  }

  completeTitle(newTitle) {
    this.setState({
      value: titleCase(newTitle),
      taxonomies: [],
      selectedTaxonomyIndex: 0,
    });
  }

  render() {
    const { taxonomies } = this.state;

    return (
      <div>
        <input
          required
          autoComplete="off"
          name={this.props.fieldName}
          id={this.props.fieldId}
          className="form-control search"
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          type="text"
        />

        <div className="list-group mt-2">
          {taxonomies.map((taxonomy, i) => {
            return (
              <button
                key={taxonomy.id}
                className={classNames('list-group-item', 'text-left', {
                  active: i === this.state.selectedTaxonomyIndex,
                })}
                onClick={e => this.onSelectFor(e, taxonomy.title)}
              >
                {titleCase(taxonomy.title)}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}
