import get from 'lodash/get';
import {
  CHANGE,
  REGISTER,
  REMOVE_FIELD,
  SET_SUBMITTING,
  SET_SUBMISSION_ERROR,
  SET_VALIDATION_ERRORS,
  SET_SHOULD_VALIDATE,
} from '../constants';

const DEFAULT_ERROR_MESSAGE = 'is invalid';

export const change = (path, value) => ({
  type: CHANGE,
  payload: { path, value },
});

export const registerField = (path, initialValue = '') => ({
  type: REGISTER,
  payload: { path, initialValue },
});

export const removeField = path => ({
  type: REMOVE_FIELD,
  payload: path,
});

export const setSubmitting = (form, isSubmitting) => ({
  type: SET_SUBMITTING,
  payload: { isSubmitting, form },
});

export const setShouldValidate = (form, shouldValidate) => ({
  type: SET_SHOULD_VALIDATE,
  payload: { shouldValidate, form },
});

export const submitDuckForm = (form, withValidation = true) => {
  return dispatch => {
    dispatch(setShouldValidate(form, withValidation));
    dispatch(setSubmitting(form, true));
  };
};

export const setFormSubmissionError = (form, err) => ({
  type: SET_SUBMISSION_ERROR,
  payload: {
    form,
    error: err || 'Unknown Error',
  },
});

export const setFormValidationErrors = (formName, errors) => {
  return (dispatch, getState) => {
    const form = get(getState(), `form.${formName}`, {});
    if (form.callbacks && form.callbacks.onValidationError) {
      form.callbacks.onValidationError(errors);
    }
    dispatch({
      type: SET_VALIDATION_ERRORS,
      payload: { form: formName, errors },
    });
  };
};

export const clearValidationErrors = (id, formName) => {
  return (dispatch, getState) => {
    const form = get(getState(), `form.${formName}`, {});
    const previousErrors = get(form, `validationErrors.${id}`);
    if (previousErrors && previousErrors.length > 0) {
      const errors = {
        ...form.validationErrors,
        [id]: [],
      };
      dispatch({
        type: SET_VALIDATION_ERRORS,
        payload: { form: formName, errors },
      });
    }
  };
};

export const serializeForm = (data = {}, serializers) => {
  if (!serializers) return data;

  const serialized = {};
  Object.keys(data).forEach(key => {
    if (serializers[key]) {
      serialized[key] = serializers[key](data[key]);
    } else {
      serialized[key] = data[key];
    }
  });
  return serialized;
};

const validateField = (validators, value) => {
  const errors = [];
  validators.forEach(validator => {
    const error = validator(value);
    if (error) {
      const message = typeof error === 'string' ? error : DEFAULT_ERROR_MESSAGE;
      errors.push(message);
    }
  });
  return errors;
};

export const validateForm = (data = {}, validators) => {
  if (!validators) return false;

  const errors = {};

  Object.keys(data).forEach(key => {
    if (validators[key] && validators[key].length > 0) {
      const fieldErrors = validateField(validators[key], data[key]);
      if (fieldErrors && fieldErrors.length > 0) {
        errors[key] = fieldErrors;
      }
    }
  });

  return Object.keys(errors).length > 0 ? errors : false;
};

export const remoteSubmitForm = (formName, formData, callback, afterSubmit) => {
  return (dispatch, getState) => {
    const promise = callback(formData, dispatch, getState);
    try {
      return promise.then(() => {
        dispatch(setSubmitting(formName, false));
        dispatch(setShouldValidate(formName, true));
        afterSubmit();
      });
    } catch (e) {
      throw Error('Submission callback must return a promise.');
    }
  };
};
