import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class ImageWithModal extends Component {
  state = { showModal: false };

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  render() {
    const { image, title } = this.props;
    const { showModal } = this.state;

    return (
      <div className="image-with-modal">
        <button
          className="screenshot btn rounded p-0 position-relative d-none d-md-inline-block"
          onClick={this.toggleModal}
        >
          <img src={image} className="card shadow w-100" />
          <i className="material-icons">open_in_new</i>
        </button>

        <Modal isOpen={showModal} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
          <ModalBody>
            <img src={image} className="card shadow w-100" />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
