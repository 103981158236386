import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { get } from 'app/common/api';
import MaterialIcon from 'app/components/MaterialIcon';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';

const FORWARD_SLASH = 191;
const T_KEY = 84;
const ESC_KEY = 27;
const ENTER_KEY = 13;

export default class OrgSwitcher extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChange = debounce(this.handleInputChange, 500);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.formatItem = this.formatItem.bind(this);

    this.state = {
      modalOpen: false,
      organizations: [],
      isLoading: false,
      value: null,
    };
  }

  componentDidMount() {
    window.__modifier_pressed__ = false;

    document.addEventListener('keyup', e => {
      if (window.__modifier_pressed__) {
        if (e.which === T_KEY) {
          if (includes(['INPUT', 'TEXTAREA'], document.activeElement.nodeName))
            return;

          this.setState({ modalOpen: true });
        }
        // Always set back to false
        window.__modifier_pressed__ = false;
      }

      if (e.which === FORWARD_SLASH) {
        window.__modifier_pressed__ = true;
      }

      if (e.which === ESC_KEY && this.state.modalOpen) {
        this.setState({ modalOpen: false });
      }
    });
  }

  handleInputChange(searchTerm) {
    this.setState({ isLoading: true, value: searchTerm });

    get(`/admin/organization/search_switch?term=${searchTerm}`).then(
      organizations => {
        this.setState({ organizations, isLoading: false });
      },
    );
  }

  handleChange({ id }) {
    window.location = `/admin/organization/switch?id=${id}`;
  }

  handleKeyDown(event) {
    if (event.which === ENTER_KEY) {
      const { value } = event.target;
      const isNumeric = Number.isFinite(Number(value));

      if (isNumeric && !isEmpty(value)) {
        window.location = `/admin/organization/switch?id=${value}`;
      }
    }
  }

  formatItem({ id, name }) {
    return (
      <div>
        <span>
          <span className="text-muted small mr-1">{id}</span>
          {name}
        </span>
      </div>
    );
  }

  render() {
    if (!this.state.modalOpen) return null;

    const { organizations, isLoading, value } = this.state;

    return (
      <Modal isOpen>
        <ModalBody>
          <div>
            <h4 className="h4">Organization Switcher</h4>
            <Select
              autoFocus
              options={organizations}
              placeholder="🏢 Search for an organization..."
              isLoading={isLoading}
              onInputChange={this.handleInputChange}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              getOptionValue={option => option.id}
              getOptionLabel={option => `${option.name}`}
              formatOptionLabel={this.formatItem}
              value={value}
            />
          </div>

          <div className="mt-2">
            <span className="mr-3">
              <MaterialIcon>arrow_upward</MaterialIcon>
              <MaterialIcon>arrow_downward</MaterialIcon> to navigate
            </span>

            <span className="mr-3">
              <b className="pr-1">enter</b>
              to switch orgs
            </span>

            <span className="mr-3">
              <b className="pr-1">esc</b>
              to cancel
            </span>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
