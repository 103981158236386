import React from 'react';
import RichTextEditor from 'app/components/admin/RichTextEditor';
import MaterialIcon from 'app/components/MaterialIcon';

const Action = ({
  data,
  handleActionTypeChange,
  handleRecipientTypeChange,
  handleBodyChange,
  handleBodyTypeChange,
  handleURLChange,
  handleWebHookBodyChange,
  handleEmailChange,
  handleCCChange,
  handleSubjectChange,
  handleOnDelete,
  disabled,
}) => (
  /* eslint-disable max-len */
  <div className="row">
    <div className="form-group col-md-4">
      <label htmlFor="actionType">Action type</label>
      <select
        value={data.type}
        className="form-control"
        id="actionType"
        onChange={handleActionTypeChange}
        disabled={disabled}
      >
        <option />
        <option value="email">Notification: Email</option>
        <option value="webhook">Notification: Webhook</option>
      </select>
    </div>
    <div className="col-md-7">
      {data.type === 'webhook' && (
        <div>
          <div className="form-group">
            <label htmlFor="url">Webhook URL</label>
            <input
              value={data.url || ''}
              onChange={handleURLChange}
              className="form-control"
              type="url"
              id="cc"
              placeholder="http://example.com/webhook"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="body">Webhook Body</label>
            <textarea
              onChange={handleWebHookBodyChange}
              value={data.body || ''}
              className="form-control"
              id="body"
              rows="4"
              disabled={disabled}
            />
          </div>
        </div>
      )}
      {data.type === 'email' && (
        <div>
          <div className="form-group">
            <label htmlFor="recipientType">Recipient Type</label>
            <select
              value={data.recipient_type}
              className="form-control"
              id="recipientType"
              onChange={handleRecipientTypeChange}
              disabled={disabled}
            >
              <option />
              <option value="admins">All Administrators</option>
              <option value="external">External Addresses</option>
            </select>
          </div>
          {data.recipient_type === 'external' && (
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                value={data.email || ''}
                onChange={handleEmailChange}
                className="form-control"
                type="url"
                id="email"
                placeholder="Email address"
                disabled={disabled}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="cc">CC email addresses</label>
            <input
              value={data.cc || ''}
              onChange={handleCCChange}
              className="form-control"
              type="url"
              id="cc"
              placeholder="CC"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Message Subject</label>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="float-right"
              data-toggle="modal"
              data-target="#message_template_variables_modal"
            >
              Show template variables
            </a>
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
            <input
              value={data.subject || ''}
              onChange={handleSubjectChange}
              className="form-control"
              type="url"
              id="subject"
              placeholder="Subject"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="webhookBody">Message Body</label>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="float-right"
              data-toggle="modal"
              data-target="#message_template_variables_modal"
            >
              Show template variables
            </a>
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
            {disabled ? (
              <div>{data.body}</div>
            ) : (
              <RichTextEditor
                value={data.body || ''}
                type={data.body_type}
                onValueChange={handleBodyChange}
                onTypeChange={handleBodyTypeChange}
              />
            )}
          </div>
        </div>
      )}
    </div>
    {!disabled && (
      <div className="col-md-1">
        <label>&nbsp;</label>
        <button
          onClick={handleOnDelete}
          type="button"
          className="btn btn-outline-danger btn-sm mt-4"
        >
          <MaterialIcon>delete</MaterialIcon>
        </button>
      </div>
    )}
  </div>
  /* eslint-enable max-len */
);

export default Action;
