import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@indeed/ifl-components';

class ReactAudioPlayer extends Component {
  componentDidMount() {
    const audio = this._audioEl;

    this.updateVolume(this.props.volume);
    audio.addEventListener('error', this.handleError);

    // When enough of the file has downloaded to start playing
    audio.addEventListener('canplay', this.handleCanPlay);

    // When enough of the file has downloaded to play the entire file
    audio.addEventListener('canplaythrough', this.handleCanPlayThough);

    // When audio play starts
    audio.addEventListener('play', this.handlePlay);

    // When unloading the audio player (switching to another src)
    audio.addEventListener('abort', this.handleAbort);

    // When the file has finished playing to the end
    audio.addEventListener('ended', this.handleEnded);

    // When the user pauses playback
    audio.addEventListener('pause', this.handlePause);

    // When the user drags the time indicator to a new time
    audio.addEventListener('seeked', this.handleSeeked);
    audio.addEventListener('loadedmetadata', this.handleLoadedMetaData);
    audio.addEventListener('volumechange', this.handleVolumeChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateVolume(nextProps.volume);
  }

  componentWillUnmount() {
    const audio = this._audioEl;

    audio.removeEventListener('error', this.handleError);
    audio.removeEventListener('canplay', this.handleCanPlay);
    audio.removeEventListener('canplaythrough', this.handleCanPlayThough);
    audio.removeEventListener('play', this.handlePlay);
    audio.removeEventListener('abort', this.handleAbort);
    audio.removeEventListener('ended', this.handleEnded);
    audio.removeEventListener('pause', this.handlePause);
    audio.removeEventListener('seeked', this.handleSeeked);
    audio.removeEventListener('loadedmetadata', this.handleLoadedMetaData);
    audio.removeEventListener('volumechange', this.handleVolumeChange);

    this.clearListenTrack();
    this._audioEl = null;
  }

  get audioEl() {
    return this._audioEl ? this._audioEl : {};
  }

  /**
   * Set an interval to call props.onListen every props.listenInterval time period
   */
  setListenTrack() {
    if (this.listenTracker) return;

    this.listenTracker = setInterval(() => {
      this.props.onListen(this.audioEl.currentTime);
    }, this.props.listenInterval);
  }

  handleError = e => {
    this.props.onError(e);
  };

  handleCanPlay = e => {
    this.props.onCanPlay(e);
  };

  handleCanPlayThough = e => {
    this.props.onCanPlayThrough(e);
  };

  handlePlay = e => {
    this.setListenTrack();
    this.props.onPlay(e);
  };

  handleAbort = e => {
    this.clearListenTrack();
    this.props.onAbort(e);
  };

  handleEnded = e => {
    this.clearListenTrack();
    this.props.onEnded(e);
  };

  handlePause = e => {
    this.clearListenTrack();
    this.props.onPause(e);
  };

  handleSeeked = e => {
    this.props.onSeeked(e);
  };

  handleLoadedMetaData = e => {
    this.props.onLoadedMetadata(e);
  };

  handleVolumeChange = e => {
    this.props.onVolumeChanged(e);
  };

  /**
   * Set the volume on the audio element from props
   * @param {Number} volume
   */
  updateVolume(volume) {
    if (typeof volume === 'number' && volume !== this.audioEl.volume) {
      this.audioEl.volume = volume;
    }
  }

  /**
   * Clear the onListen interval
   */
  clearListenTrack() {
    if (this.listenTracker) {
      clearInterval(this.listenTracker);
      this.listenTracker = null;
    }
  }

  render() {
    const incompatibilityMessage = this.props.children || (
      <Text>
        Your browser does not support the <Text as="code">audio</Text> element.
      </Text>
    );

    // Set controls to be true by default unless explicity stated otherwise
    const controls = !(this.props.controls === false);

    // Set lockscreen / process audio title on devices
    const title = this.props.title ? this.props.title : this.props.src;

    // Some props should only be added if specified
    const conditionalProps = {};
    if (this.props.controlsList) {
      conditionalProps.controlsList = this.props.controlsList;
    }

    return (
      // Disabling rule since a trasncript option is added on the Builder for the
      // "form" (frontend/app/apps/Reform/components/form/AudioPlayer.jsx) usage of this component
      // and for the "question" one (frontend/app/apps/Reform/components/questions/AudioRecording/AudioPlayer.jsx)
      // this is a self-recorded audio that can be then played so no transcript for a "freshly-uploaded" audio is necessary
      // eslint-disable-next-line @indeed/uxqual/media-has-caption
      <Box
        as="audio"
        autoPlay={this.props.autoPlay}
        sx={this.props.sx}
        controls={controls}
        crossOrigin={this.props.crossOrigin}
        id={this.props.id}
        loop={this.props.loop}
        muted={this.props.muted}
        onPlay={this.onPlay}
        preload={this.props.preload}
        ref={ref => {
          this._audioEl = ref;
        }}
        src={this.props.src}
        style={this.props.style}
        title={title}
        data-testid="react-audio-player"
        {...conditionalProps}
      >
        {incompatibilityMessage}
      </Box>
    );
  }
}

ReactAudioPlayer.defaultProps = {
  autoPlay: false,
  children: null,
  sx: {},
  controls: false,
  controlsList: '',
  crossOrigin: null,
  id: '',
  listenInterval: 10000,
  loop: false,
  muted: false,
  onAbort: () => {},
  onCanPlay: () => {},
  onCanPlayThrough: () => {},
  onEnded: () => {},
  onError: () => {},
  onListen: () => {},
  onPause: () => {},
  onPlay: () => {},
  onSeeked: () => {},
  onVolumeChanged: () => {},
  onLoadedMetadata: () => {},
  preload: 'metadata',
  src: null,
  style: {},
  title: '',
  volume: 1.0,
};

ReactAudioPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  children: PropTypes.element,
  sx: PropTypes.shape(PropTypes.string),
  controls: PropTypes.bool,
  controlsList: PropTypes.string,
  crossOrigin: PropTypes.string,
  id: PropTypes.string,
  listenInterval: PropTypes.number,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onAbort: PropTypes.func,
  onCanPlay: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onListen: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onSeeked: PropTypes.func,
  onVolumeChanged: PropTypes.func,
  preload: PropTypes.oneOf(['', 'none', 'metadata', 'auto']),
  src: PropTypes.string, // Not required b/c can use <source>
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  volume: PropTypes.number,
};

export default ReactAudioPlayer;
