import React, { Component } from 'react';
import Select from 'react-select';
import { get } from 'app/common/api';
import debounce from 'lodash/debounce';

export default class OrganizationsFilter extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearch = debounce(this.handleSearch, 250);
    this.handleChange = this.handleChange.bind(this);
    this.formatSearchResultItem = this.formatSearchResultItem.bind(this);

    this.state = {
      organizations: [],
      isLoading: false,
    };
  }

  handleChange({ id }) {
    window.location.href = `?organization_id=${id}`;
  }

  handleSearch(searchTerm) {
    this.setState({ isLoading: true });
    get(`/admin/organization/search?term=${searchTerm}`).then(organizations => {
      this.setState({ organizations, isLoading: false });
    });
  }

  formatSearchResultItem({ id, name }) {
    return (
      <div>
        <span>
          <span className="text-muted small mr-1">{id}</span>
          {name}
        </span>
      </div>
    );
  }

  render() {
    const { organizations, isLoading } = this.state;
    const { filteringOrganization } = this.props;

    return (
      <div className="mb-0">
        {filteringOrganization ? (
          <div className="alert alert-info small py-2 px-3 m-0 text-center">
            Showing results for
            <strong> {filteringOrganization.name}</strong>
            <span className="text-muted small ml-1">
              {filteringOrganization.id}
            </span>
            <a href="?" className="ml-1">
              clear filter
            </a>
          </div>
        ) : (
          <Select
            isLoading={isLoading}
            className="basic-search"
            options={organizations}
            placeholder="🏢 Filter by organization..."
            onInputChange={this.handleSearch}
            onChange={this.handleChange}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            formatOptionLabel={this.formatSearchResultItem}
          />
        )}
      </div>
    );
  }
}
