import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class BullhornRedirectModal extends Component {
  state = { showModal: false };

  timeout = null;

  toggleModal = () => {
    if (this.state.showModal) clearTimeout(this.timeout);
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  openModal = () => {
    this.toggleModal();

    this.timeout = window.setTimeout(() => {
      this.handleSubmit();
    }, 5000);
  };

  handleSubmit = () => {
    this.formRef && this.formRef.submit();
  };

  render() {
    const { showModal } = this.state;
    const { baseUrl, clientId, redirectUrl, stateValue } = this.props;

    return (
      <div className="image-with-modal">
        <button
          className="btn btn-primary btn-wide px-4 mx-3 mb-3 semi-bold"
          onClick={this.openModal}
        >
          Setup
        </button>

        <Modal isOpen={showModal} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal} />
          <ModalBody className="text-center mb-3 mx-3 pt-0">
            <img src="/icons/spinner.gif" height="60" className="mb-4" />
            <h4 className="bold mb-3">We are now taking you to Bullhorn</h4>
            <div className="mb-3 text-muted-dark">
              You are now being redirected to Bullhorn to connect your Indeed
              Assessments account. If you did not wish to do this, please click{' '}
              <button className="btn btn-link p-0" onClick={this.toggleModal}>
                here
              </button>
              to stop the redirect.
            </div>
            <form
              ref={formRef => {
                this.formRef = formRef;
              }}
              action={baseUrl}
              acceptCharset="UTF-8"
              method="get"
            >
              <input type="hidden" name="client_id" value={clientId} />
              <input type="hidden" name="redirect_uri" value={redirectUrl} />
              <input type="hidden" name="state" value={stateValue} />
              <input type="hidden" name="response_type" value="code" />
              <div className="text-muted small">
                Is this taking a while?
                <button
                  className="btn btn-link small-text p-0"
                  onClick={this.handleSubmit}
                >
                  Click here
                </button>
                to redirect yourself.
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
