import React from 'react';
import { Box, Flex, keyframes } from '@indeed/ifl-components';

const dotAnimation = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
`;

const Loader = ({ customMarginTopSx }) => {
  const commonDotSx = {
    display: 'block',
    backgroundColor: 'primary.800',
    borderRadius: 'full',
    mx: 1,
    width: '0.3125rem',
    height: '0.3125rem',
  };

  return (
    <Flex sx={{ justifyContent: 'center', marginTop: customMarginTopSx }}>
      <Box
        sx={{
          ...commonDotSx,
          animation: `${dotAnimation} 2.5s 0.2s infinite`,
        }}
      />
      <Box
        sx={{
          ...commonDotSx,
          animation: `${dotAnimation} 2.5s 0.4s infinite`,
        }}
      />
      <Box
        sx={{
          ...commonDotSx,
          animation: `${dotAnimation} 2.5s 0.6s infinite`,
        }}
      />
    </Flex>
  );
};

export default Loader;
