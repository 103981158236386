import React, { Component } from 'react';
import { get } from 'app/common/api';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

export default class AdminSearch extends Component {
  constructor(props) {
    super(props);

    this.searchAdmins = this.searchAdmins.bind(this);
    this.searchAdmins = debounce(this.searchAdmins, 250);
    this.handleChange = this.handleChange.bind(this);
    this.formatSearchResultItem = this.formatSearchResultItem.bind(this);

    this.state = {
      admins: [],
      isLoading: false,
    };
  }

  handleChange(admin) {
    window.location.href = `/admin/audit_admins/${admin.id}`;
  }

  formatSearchResultItem({ id, name, email, role }) {
    return (
      <div>
        <span>
          <span className="text-muted small">{id} </span>
          <strong>{name}</strong>
          <span className="text-muted small"> {email}</span>
          <span>&nbsp;</span>
        </span>
        {!isEmpty(role) && (
          <span className="badge badge-secondary small">{role}</span>
        )}
      </div>
    );
  }

  searchAdmins(term) {
    if (isEmpty(term)) {
      return;
    }

    const { filteringOrganization } = this.props;
    let searchParams = { term };

    if (!isEmpty(filteringOrganization)) {
      searchParams = {
        ...searchParams,
        organization_id: filteringOrganization.id,
      };
    }
    const queryParams = new URLSearchParams(searchParams);
    this.setState({ isLoading: true });

    get(`/admin/audit_admins/search.json?${queryParams.toString()}`).then(
      admins => {
        this.setState({ admins, isLoading: false });
      },
    );
  }

  render() {
    const { admins, isLoading } = this.state;

    return (
      <Select
        isLoading={isLoading}
        className="basic-search"
        options={admins}
        placeholder="👤 Search users..."
        onInputChange={this.searchAdmins}
        onChange={this.handleChange}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.email}
        formatOptionLabel={this.formatSearchResultItem}
      />
    );
  }
}
