import React from 'react';
import MaterialIcon from 'app/components/MaterialIcon';

const Condition = ({
  data,
  handleConditionTypeChange,
  handleComparisonChange,
  onInputChange,
  handleOnDelete,
  disabled,
}) => (
  <div className="row">
    <div className="form-group col-md-3">
      <label htmlFor="conditionType">Condition type</label>
      <select
        value={data.type}
        className="form-control"
        id="conditionType"
        onChange={handleConditionTypeChange}
        disabled={disabled}
      >
        <option />
        <option value="remaining_assessment_count">Remaining modules</option>
        <option value="candidate_weighted_percentage_score">
          Candidate: Weighted percentage score
        </option>
      </select>
    </div>
    <div className="form-group col-md-2">
      <label htmlFor="comparison">Comparison</label>
      <select
        value={data.operator}
        className="form-control"
        id="comparison"
        onChange={handleComparisonChange}
        disabled={disabled}
      >
        <option />
        <option value="equals">Equals</option>
        <option value="greater_than">Greater Than</option>
        <option value="greater_than_or_equal">Greater Than or Equal</option>
        <option value="less_than">Less Than</option>
        <option value="less_than_or_equal">Less Than or Equal</option>
      </select>
    </div>
    <div className="col-md-6">
      {data.type === 'remaining_assessment_count' ? (
        <div className="form-group">
          <label htmlFor="conditionInput">Value</label>
          <input
            value={data.value}
            onChange={onInputChange}
            type="number"
            className="form-control"
            id="conditionInput"
            aria-describedby="conditionValue"
            disabled={disabled}
          />
        </div>
      ) : (
        <div className="form-group">
          <label>Percentage Score</label>
          <select
            value={data.value}
            onChange={onInputChange}
            className="form-control"
            disabled={disabled}
          >
            <option />
            <option value="0.0">0%</option>
            <option value="0.05">5%</option>
            <option value="0.1">10%</option>
            <option value="0.15">15%</option>
            <option value="0.2">20%</option>
            <option value="0.25">25%</option>
            <option value="0.3">30%</option>
            <option value="0.35">35%</option>
            <option value="0.4">40%</option>
            <option value="0.45">45%</option>
            <option value="0.5">50%</option>
            <option value="0.55">55%</option>
            <option value="0.6">60%</option>
            <option value="0.65">65%</option>
            <option value="0.7">70%</option>
            <option value="0.75">75%</option>
            <option value="0.8">80%</option>
            <option value="0.85">85%</option>
            <option value="0.9">90%</option>
            <option value="0.95">95%</option>
            <option value="1.0">100%</option>
          </select>
        </div>
      )}
    </div>
    {!disabled && (
      <div className="col-md-1">
        <label>&nbsp;</label>
        <button
          onClick={handleOnDelete}
          type="button"
          className="btn btn-outline-danger btn-sm mt-4"
        >
          <MaterialIcon>delete</MaterialIcon>
        </button>
      </div>
    )}
  </div>
);

export default Condition;
