import React from 'react';
import Rnd from 'react-rnd';
import Field from 'app/components/forms/Field';
import t from 'app/common/translate';
import ImageMapImage from 'app/components/ImageMapImage';
import { Box, Button, Flex, keyframes, Text } from '@indeed/ifl-components';

const CLICK_IDENTIFIER = 'image-map-click';
export const CLICK_WIDTH = 22;
export const HALF_CLICK_WIDTH = CLICK_WIDTH / 2;

export const adjustDragToAccountForWidth = (coordinates, multiplier) => {
  return {
    x: (coordinates.x + HALF_CLICK_WIDTH) * multiplier,
    y: (coordinates.y + HALF_CLICK_WIDTH) * multiplier,
  };
};

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(110, 211, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(110, 211, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(110, 211, 255, 0);
  }
`;

export class ImageMap extends React.Component {
  get clicks() {
    return this.props.value;
  }

  get maxClicks() {
    return this.props.question.max_image_map_clicks || 0;
  }

  getClickCoordinates = (event, multiplier) => {
    const bounds = event.target.getBoundingClientRect();
    const x = (event.clientX - bounds.left) * multiplier;
    const y = (event.clientY - bounds.top) * multiplier;
    return { x, y };
  };

  handleImageClick = (e, reductionPercentage) => {
    // Ignore drag events
    if (e.target.getAttribute('data-testid') !== CLICK_IDENTIFIER) {
      const coordinates = this.getClickCoordinates(e, 1 / reductionPercentage);
      if (this.clicks.length < this.maxClicks) {
        this.addClick(coordinates);
      }
    }
  };

  addClick = coordinates => {
    const { onChange } = this.props;
    const newClick = { id: this.clicks.length, ...coordinates };
    const clicks = this.clicks.slice();
    clicks.push(newClick);
    onChange(clicks);
  };

  handleDrag = (e, newCoordinates, i, multiplier) => {
    const { onChange } = this.props;
    const coordinates = adjustDragToAccountForWidth(newCoordinates, multiplier);
    const clicks = this.clicks.slice();
    clicks[i] = { ...clicks[i], ...coordinates };
    onChange(clicks);
  };

  handleReset = e => {
    e.preventDefault();
    const { onChange } = this.props;
    onChange([]);
  };

  render() {
    const { question } = this.props;

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ImageMapImage image={question.image} onClick={this.handleImageClick}>
          {(getCoordinates, reductionPercentage) => {
            return this.clicks.map((click, i) => {
              const multiplier = 1 / reductionPercentage;
              const position = getCoordinates(click.x, click.y);
              return (
                <Rnd
                  key={click.id}
                  position={{
                    x: position.left - HALF_CLICK_WIDTH,
                    y: position.top - HALF_CLICK_WIDTH,
                  }}
                  minWidth={CLICK_WIDTH}
                  minHeight={CLICK_WIDTH}
                  bounds="parent"
                  onDragStop={(e, data) =>
                    this.handleDrag(e, data, i, multiplier)
                  }
                  enableResizing={false}
                >
                  <Box
                    data-testid={CLICK_IDENTIFIER}
                    sx={{
                      display: 'block',
                      width: '1.375rem',
                      height: '1.375rem',
                      borderRadius: 'full',
                      backgroundColor: 'primary.400',
                      cursor: 'pointer',
                      animation: `2.5s infinite ${pulse}`,
                      border: '2px solid',
                      borderColor: 'neutral.0',
                    }}
                  />
                </Rnd>
              );
            });
          }}
        </ImageMapImage>
        <Text
          sx={{
            my: 2,
            color: 'neutral.800',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 1,
          }}
        >
          {t('reform.click_on_image_to_select_answer')}
        </Text>
        <Button
          type="button"
          variant="utility"
          size="sm"
          onClick={this.handleReset}
          sx={{ fontSize: 1 }}
          data-testid="image-map-reset-button"
        >
          {t('reform.reset')}
        </Button>
      </Flex>
    );
  }
}

const ImageMapField = ({ question, models, form }) => {
  const {
    stepProgression: { id },
  } = models;

  return (
    <Field
      form={form}
      id={question.id}
      initialValue={[]}
      required={question.required}
    >
      {(onChange, value) => (
        <ImageMap
          onChange={onChange}
          value={value}
          question={question}
          stepProgressionId={id}
        />
      )}
    </Field>
  );
};

export default ImageMapField;
