/**
 * Import Application
 */
import Application from 'app/config/application';

/**
 * Import App(s)
 */

/**
 * Import Plugins
 */
import railsPlugin from 'app/plugins/rails';
import bootstrapPlugin from 'app/plugins/bootstrap';
import notificationsPlugin from 'app/plugins/notifications';
import autosizePlugin from 'app/plugins/autosize';
import clickableCellPlugin from 'app/plugins/clickable_cell';

/**
 * Import Components
 */
import OrgSwitcher from 'app/components/admin/OrgSwitcher';
import TriggerConditions from 'app/components/admin/triggers/index';
import SelectField from 'app/components/admin/SelectField';
import ImageGallery from 'app/components/admin/ImageGallery';
import ImageWithModal from 'app/components/admin/ImageWithModal';
import NewStepForm from 'app/components/admin/NewStepForm';
import HistogramChart from 'app/components/admin/HistogramChart';
import WistiaPlayer from 'app/components/WistiaPlayer';
import CandidateInvite from 'app/components/admin/CandidateInvite';
import CandidateInviteList from 'app/components/admin/CandidateInviteList';
import SurveyBuilder from 'app/components/admin/SurveyBuilder';
import SurveyQuestion from 'app/components/admin/SurveyQuestion';
import ClickToCopy from 'app/components/admin/ClickToCopy';
import BullhornRedirectModal from 'app/components/admin/BullhornRedirectModal';
import ReactAudioPlayer from 'app/components/ReactAudioPlayer';
import ImageMapAnswerPreview from 'app/components/ImageMapAnswerPreview';
import TaxonomyAutocompleteField from 'app/components/admin/TaxonomyAutocompleteField';
import RichTextExpander from 'app/components/admin/RichTextExpander';
import CloneAssessment from 'app/components/admin/CloneAssessment';
import PromoteClonedAssessment from 'app/components/admin/PromoteClonedAssessment';
import TaxonomyAssessmentsForm from 'app/components/admin/TaxonomyAssessmentsForm';
import OccupationAssessmentsForm from 'app/components/admin/OccupationAssessmentsForm';
import AspenPermissions from 'app/components/admin/admins/AspenPermissions';
import AdminSearch from 'app/components/admin/admins/AdminSearch';
import OrganizationsFilter from 'app/components/admin/admins/OrganizationsFilter';
import MoveAdminToOrganization from 'app/components/admin/admins/MoveAdminToOrganization';
import CompetencyEditor from '../app/components/admin/CompetencyEditor';

/**
 * Import Initializers
 */
import 'app/initializers/admin';

/**
 * Create application instance (required)
 */
const app = (window.app = new Application());

/**
 * Register App(s)
 */

/**
 * Register Plugins
 */
app.registerPlugins([
  railsPlugin,
  bootstrapPlugin,
  notificationsPlugin,
  autosizePlugin,
  clickableCellPlugin,
]);

/**
 * Register Components
 */
/* eslint-disable quote-props */
app.registerComponents({
  TriggerConditions,
  SelectField,
  OrgSwitcher,
  ImageGallery,
  ImageWithModal,
  NewStepForm,
  HistogramChart,
  WistiaPlayer,
  CandidateInvite,
  CandidateInviteList,
  SurveyBuilder,
  SurveyQuestion,
  ClickToCopy,
  BullhornRedirectModal,
  ReactAudioPlayer,
  ImageMapAnswerPreview,
  TaxonomyAutocompleteField,
  RichTextExpander,
  CloneAssessment,
  PromoteClonedAssessment,
  TaxonomyAssessmentsForm,
  OccupationAssessmentsForm,
  AspenPermissions,
  AdminSearch,
  OrganizationsFilter,
  MoveAdminToOrganization,
  CompetencyEditor,
});
/* eslint-enable quote-props */

app.ready();
