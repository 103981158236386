import React from 'react';
import RichTextEditor from './RichTextEditor';

const CompetencyEditor = ({ id, value }) => {
  const updateDescriptionField = e => {
    const descriptionField = document.querySelector(
      '#competency-description-field',
    );

    descriptionField.value = e;
  };

  return (
    <RichTextEditor
      name="competency[description]"
      id={id}
      value={value}
      type="wysiwyg"
      onValueChange={e => updateDescriptionField(e)}
    />
  );
};

export default CompetencyEditor;
