import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CandidateInvite extends Component {
  constructor(props) {
    super(props);
    this.state = { isValid: true, showInvalid: false };
    this.submitButtonRef = React.createRef();
    this.firstNameInputRef = React.createRef();
    this.emailInputRef = React.createRef();
  }

  componentDidMount() {
    this.firstNameInputRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    const { firstInvalidInvite, id } = this.props;

    if (
      firstInvalidInvite &&
      firstInvalidInvite !== prevProps.firstInvalidInvite &&
      firstInvalidInvite.id === id
    ) {
      this.emailInputRef.current.focus();
    }
  }

  onEmailChanged = event => {
    const email = event.target.value;
    const isValid = this.isValidEmail(email);
    this.setState({ isValid });
    const errorMessage = isValid ? '' : `${email} is an invalid email.`;
    this.props.onChanged(this.props.index, isValid, errorMessage);
  };

  onEmailBlur = () => {
    if (this.state.isValid && this.state.showInvalid) {
      this.setState({ showInvalid: false });
    } else if (!this.state.isValid && !this.state.showInvalid) {
      this.setState({ showInvalid: true });
    }
  };

  isValidEmail(email) {
    const EMAIL_VALIDATION_REGEX = /[^@]+@[^@]+/;
    return !email || EMAIL_VALIDATION_REGEX.test(email);
  }

  render() {
    const { formPrefix, id } = this.props;
    const { showInvalid } = this.state;

    return (
      <div className="invite-candidate-row container px-0 pt-2 divider-dashed">
        <div className="form-row">
          <div className="form-group col-sm">
            <label htmlFor="first_name" className="d-inline-block text-dark">
              First Name
            </label>
            <input
              className="form-control border-2 rounded-2"
              id={`invite_first_name_${id}`}
              type="text"
              name={`${formPrefix}[first_name]`}
              ref={this.firstNameInputRef}
            />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="last_name" className="d-inline-block text-dark">
              Last Name
            </label>
            <input
              className="form-control border-2 rounded-2 "
              id={`invite_last_name_${id}`}
              type="text"
              name={`${formPrefix}[last_name]`}
            />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="email" className="text-dark">
              Email
            </label>
            <input
              className={`form-control border-2 rounded-2 ${
                showInvalid ? 'is-invalid' : ''
              }`}
              id={`invite_email_${id}`}
              type="text"
              name={`${formPrefix}[email]`}
              onChange={this.onEmailChanged}
              onBlur={this.onEmailBlur}
              ref={this.emailInputRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

CandidateInvite.propTypes = {
  id: PropTypes.number.isRequired,
  onChanged: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  formPrefix: PropTypes.string.isRequired,
  firstInvalidInvite: PropTypes.object,
};

CandidateInvite.defaultProps = {
  firstInvalidInvite: null,
};
