import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsGroupedCategories from 'highcharts-grouped-categories';
import forEach from 'lodash/forEach';
import clamp from 'lodash/clamp';
import set from 'lodash/set';

HighchartsGroupedCategories(Highcharts);

export const LEVEL_BORDER_COLOR = '#CCC';
export const BIN_BORDER_COLOR = '#909090';
export const GRAY_COLUMN_COLOR = '#ECECEC';
export const ABOVE_COMPLETED_COLOR = '#2557A7';
export const BELOW_COMPLETED_COLOR = '#2D2D2D';

export const DISTANCE_FROM_COLUMN_TOP = -12;
export const DISTANCE_FROM_COLUMN_SIDE = -10;

export const PLOT_LINES_ADJUSTMENT = 0.5;

export function updatePathToHeightOfChart(path) {
  const pathParts = path.split(' ');
  pathParts[2] = 0; // make path the full height of chart area
  const updatedPath = pathParts.join(' ');

  return updatedPath;
}

export function positionLabelBetweenLevels(
  higherLevelPosition,
  lowerLevelPosition,
  labelWidth,
) {
  const horizontalOffset =
    (higherLevelPosition - lowerLevelPosition - labelWidth) / 2;
  const position = Number(lowerLevelPosition) + Number(horizontalOffset);
  const clampedPosition = clamp(position, 0, Infinity); // don't allow position off the chart

  return clampedPosition;
}

export function getBinPlotLines() {
  const bins = [-0.5, 0.5, 1.5, 2.5, 3.5, 4.5];

  const binPlotLines = bins.map(bin => {
    return {
      color: LEVEL_BORDER_COLOR,
      className: 'level',
      width: 1,
      value: bin,
      zIndex: 6,
    };
  });

  return binPlotLines;
}

export function getDataLabelImage(colorHex) {
  const colorName = colorHex === ABOVE_COMPLETED_COLOR ? 'blue' : 'black';

  return `<img src="/icons/person-pin-${colorName}.svg" />`;
}

export function getData(data) {
  return data.map(bin => {
    if (
      bin.color === ABOVE_COMPLETED_COLOR ||
      bin.color === BELOW_COMPLETED_COLOR
    ) {
      return {
        ...bin,
        dataLabels: {
          enabled: true,
          useHTML: true,
          y: DISTANCE_FROM_COLUMN_TOP,
          x: DISTANCE_FROM_COLUMN_SIDE,
          crop: false,
          overflow: 'visible',
          formatter() {
            return getDataLabelImage(bin.color);
          },
        },
      };
    }
    return bin;
  });
}

export function getHoverTooltipMarkupString({
  hideCount = false,
  y = 0,
  name = '',
}) {
  const countHTML = hideCount ? '' : `<h6 class="mb-1">Count: ${y}</h6>`;

  return `<div class="histogram-tooltip">
      ${countHTML}
      <div class="text-muted">${name} points</div>
    </div>`;
}

class HistogramChart extends Component {
  componentDidMount() {
    if (this.props.data.length === 0) {
      return;
    } // return early if histogram is nil

    this.initChart();
    this.positionLabelsBetweenLevels();
  }

  onLoadCallback() {
    // remove overflow so the hovering person-pin icon doesn't get cut off
    // https://github.com/highcharts/highcharts/issues/11089
    set(this, 'container.parentNode.style.overflow', null);
  }

  getAllPlotLines() {
    return getBinPlotLines();
  }

  // center labels between their level plotLines
  positionLabelsBetweenLevels() {
    const { containerRef: chart } = this;
    if (!chart) return;

    // This gets live refs to 'highcharts-plot-line level' descendants, which are SVGPathElements.
    const levels = chart.getElementsByClassName('level');
    const labels = chart.getElementsByClassName('histogram-labels');

    forEach(labels, (label, i) => {
      const lowerLevelPosition = levels[i].getAttribute('d').split(' ')[1];
      const higherLevelPosition = levels[i + 1].getAttribute('d').split(' ')[1];
      const labelWidth = label.offsetWidth;

      const updatedPosition = positionLabelBetweenLevels(
        lowerLevelPosition,
        higherLevelPosition,
        labelWidth,
      );

      // eslint-disable-next-line no-param-reassign
      label.style.left = `${updatedPosition}px`;
    });
  }

  initChart() {
    const showYAxis = this.props.showYAxis || false;
    const { hideCount } = this.props;

    this.chart = new Highcharts.Chart({
      chart: {
        // https://www.highcharts.com/docs/chart-and-series-types/column-chart
        type: 'column',
        renderTo: this.highchartsRef,
        height: 110,
        spacingTop: 20,
        style: {
          overflow: 'visible',
        },
        events: {
          redraw: this.positionLabelsBetweenLevels.bind(this),
          load: this.onLoadCallback,
        },
        id: this.props.id,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          // formatter only accepts the return to be a string
          const {
            y,
            point: { name },
          } = this;
          return getHoverTooltipMarkupString({ hideCount, y, name });
        },
        useHTML: true,
        padding: 8,
        outside: true,
        backgroundColor: 'rgba(255,255,255,1)',
        borderColor: '#CCC',
      },
      yAxis: {
        visible: showYAxis,
        allowDecimals: false,
        title: {
          text: null,
        },
        gridLineWidth: 0,
      },

      xAxis: {
        tickColor: 'none',
        labels: {
          useHTML: true,
        },

        plotLines: this.getAllPlotLines(),

        categories: [
          {
            name: '',
            categories: ['', ''],
          },
          {
            name: '',
            categories: ['', ''],
          },
          {
            name: '',
            categories: ['', ''],
          },
          {
            name: '',
            categories: ['', ''],
          },
          {
            name: '',
            categories: ['', ''],
          },
        ],
      },

      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          groupPadding: 0,
          color: GRAY_COLUMN_COLOR,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },

      series: [
        {
          data: getData(this.props.data),
          minPointLength: 3,
        },
      ],
    });
  }

  render() {
    const { proficiencyLevels, id } = this.props;
    return (
      <div
        id={id}
        ref={el => {
          this.containerRef = el;
        }}
        className="histogram-chart-wrapper mx-auto position-relative"
      >
        <div
          ref={el => {
            this.highchartsRef = el;
          }}
        />
        <h6 className="histogram-labels">{proficiencyLevels.completed}</h6>
        <h6 className="histogram-labels">{proficiencyLevels.familiar}</h6>
        <h6 className="histogram-labels">{proficiencyLevels.proficient}</h6>
        <h6
          className="histogram-labels text-center"
          dangerouslySetInnerHTML={{
            __html: proficiencyLevels.highly_proficient,
          }}
        />
        <h6 className="histogram-labels">{proficiencyLevels.expert}</h6>
      </div>
    );
  }
}

export default HistogramChart;
