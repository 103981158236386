import React, { Component } from 'react';
import { post } from 'app/common/api';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

export default class PromoteClonedAssessment extends Component {
  static generateConfirmationCode() {
    let confirmCode = '';
    for (let i = 0; i < 6; i++) {
      // A ... Z
      confirmCode += String.fromCharCode(Math.floor(Math.random() * 25) + 65);
    }
    return confirmCode;
  }

  constructor(props) {
    super(props);

    this.confirmationCode = PromoteClonedAssessment.generateConfirmationCode();

    this.state = {
      confirmed: false,
      isUpdating: false,
      showModal: false,
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleInputChange = input => {
    if (input.target.value === this.confirmationCode) {
      this.setState({ confirmed: true });
    } else if (this.state.confirmed === true) {
      this.setState({ confirmed: false });
    }
  };

  handleConfirmButtonPressed = () => {
    this.setState({ isUpdating: true }, this.publish);
  };

  publish = () => {
    const payload = {
      id: this.props.assessmentId,
    };

    return post('/superadmin/promote_cloned_assessment', payload).then(() => {
      this.closeModal();
    });
  };

  render() {
    const { assessmentId, originalName, originalId } = this.props;
    const { showModal, confirmed, isUpdating } = this.state;

    return (
      <div>
        <Button color="secondary" onClick={this.showModal}>
          Promote
        </Button>
        <Modal isOpen={showModal} className="clone-assessment-modal">
          <ModalHeader>
            Promotoe this Cloned Assessment and Unpublish the Original
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              The following action will <strong>unpublish</strong> the original
              Assessment
              <br />
              <strong>
                {originalName} (id {originalId})
              </strong>
              <br />
              <br />
              And will <strong>publish</strong> this cloned Assessment{' '}
              <strong>(id {assessmentId})</strong>.<br />
              <br />
              To confirm, please enter the following code:
              <br />
              <strong className="text-danger">{this.confirmationCode}</strong>
            </p>

            <FormGroup>
              <div className="text-center m-4">
                <Input
                  className="text-center"
                  name="confirmationCode"
                  onChange={this.handleInputChange}
                  disabled={isUpdating}
                />
              </div>
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            {confirmed && (
              <Button
                disabled={isUpdating}
                color="danger"
                onClick={this.handleConfirmButtonPressed}
              >
                {isUpdating ? (
                  <i className="fa fa-spin fa-spinner" />
                ) : (
                  'Confirm'
                )}
              </Button>
            )}
            <Button
              disabled={isUpdating}
              color="primary"
              onClick={this.closeModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
