import React from 'react';
import Select from 'react-select';

export default class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.options.filter(
        option => props.selected.indexOf(option.value) > -1,
      ),
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  render() {
    const { name, options, disabled, placeholder } = this.props;
    const { selectedOption } = this.state;
    return (
      <div>
        <Select
          name={name}
          value={selectedOption}
          isMulti
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.handleChange}
          options={options}
        />
        {selectedOption.length === 0 && <input name={name} type="hidden" />}
      </div>
    );
  }
}
