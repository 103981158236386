import React, { Component } from 'react';
import startCase from 'lodash/startCase';
import { getCSRFToken } from 'app/lib/utils/rest';
import SurveyQuestion from './SurveyQuestion';

export default class SurveyBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyQuestions: props.surveyQuestions,
      isSubmitted: false,
      nextAssignedId: 0,
    };
  }

  componentDidMount() {
    if (this.props.surveyQuestions.length === 0) {
      return this.setState(prevState => ({
        surveyQuestions: [this.createSurveyQuestion()],
        nextAssignedId: prevState.nextAssignedId + 1,
      }));
    }

    this.setState(prevState => ({
      nextAssignedId: prevState.nextAssignedId + 1,
    }));
  }

  onSurveyQuestionChanged = index => {
    this.setState(prevState => {
      const updatedSurveyQuestion = this.createSurveyQuestion(
        prevState.surveyQuestions[index].assignedId,
      );
      const updateSurveyQuestions = prevState.surveyQuestions.slice();
      updateSurveyQuestions[index] = updatedSurveyQuestion;
      return { surveyQuestions: updateSurveyQuestions };
    });
  };

  addSurveyQuestion = () => {
    this.setState(prevState => ({
      surveyQuestions: [
        ...prevState.surveyQuestions,
        this.createSurveyQuestion(),
      ],
      nextAssignedId: prevState.nextAssignedId + 1,
    }));
  };

  handleSubmit = () => {
    this.setState({
      isSubmitted: true,
    });
  };

  createSurveyQuestion = (id = null) => {
    const { nextAssignedId } = this.state;
    let assignedId = id;
    if (assignedId === null) assignedId = nextAssignedId;

    return { assignedId };
  };

  surveyQuestionElements = () => {
    const { surveyQuestions } = this.state;
    const { formPrefix, types, languages, surveyQuestionBaseUrl } = this.props;
    return surveyQuestions.map(surveyQuestion => {
      return (
        <SurveyQuestion
          id={surveyQuestion.id}
          assignedId={surveyQuestion.assignedId}
          onChanged={this.onSurveyQuestionChanged}
          formPrefix={formPrefix}
          key={surveyQuestion.assignedId}
          types={types}
          languages={languages}
          question={surveyQuestion}
          surveyQuestionBaseUrl={surveyQuestionBaseUrl}
        />
      );
    });
  };

  render() {
    const { isSubmitted } = this.state;
    const { subjects, formAction, formMethod, survey, survey_id_names } =
      this.props;

    return (
      <div className="d-flex mb-3">
        <div className="container">
          <div className="card w-100">
            <div className="card-body">
              <form
                action={formAction}
                acceptCharset="UTF-8"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <input name="_method" type="hidden" value={formMethod} />
                <input type="hidden" name="utf8" value="✓" />
                <input
                  type="hidden"
                  value={getCSRFToken()}
                  name="authenticity_token"
                />
                <div className="mx-4">
                  <h4 className="card-title">Survey</h4>

                  {survey && (
                    <input type="hidden" name="survey[id]" value={survey.id} />
                  )}

                  <div className="form-group row">
                    <label
                      htmlFor="name"
                      className="text-dark col-sm-6 col-form-label"
                    >
                      Survey Name
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        name="survey[name]"
                        id="survey-name-input"
                        defaultValue={survey && survey.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="subject"
                      className="text-dark col-sm-6 col-form-label"
                    >
                      Survey Type (a.k.a Subject)
                    </label>
                    <div className="col-sm-6">
                      <select
                        className="form-control"
                        id="survey-subject-select"
                        name="survey[subject]"
                        defaultValue={survey ? survey.subject : ''}
                      >
                        <option value="">Select Survey Type</option>
                        {subjects.map(subject => (
                          <option value={subject[0]} key={subject[0]}>
                            {startCase(subject[1])}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="control_of_id"
                      className="text-dark col-sm-6 col-form-label"
                    >
                      Control of
                    </label>
                    <div className="col-sm-6">
                      <select
                        className="form-control"
                        id="survey-control-of-id-select"
                        name="survey[control_of_id]"
                        defaultValue={survey ? survey.control_of_id : ''}
                      >
                        <option value="">Select a survey as control</option>
                        {survey_id_names.map(pair => (
                          <option value={pair[0]} key={pair[0]}>
                            {pair[1]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <h4 className="card-title mt-5">Survey Questions</h4>

                  <div>{this.surveyQuestionElements()}</div>

                  <div className="w-100 text-right">
                    <button
                      type="button"
                      className=" d-flex align-items-center btn btn-link px-0 mb-3"
                      id="inviteAnotherCandidateLink"
                      onClick={this.addSurveyQuestion}
                    >
                      <i className="material-icons">add_circle_outline</i>
                      <span>Add another survey question</span>
                    </button>
                  </div>
                </div>
                <div className="card-footer text-right border-top-0">
                  <button
                    type="submit"
                    name="commit"
                    className="btn btn-primary btn-wide border-2 bold ml-3 p-2"
                    disabled={isSubmitted}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
