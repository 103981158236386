import { initializeOn } from 'app/lib/common';
import * as Routes from 'app/config/routes';

initializeOn(Routes.ADMIN_ROLES_EDIT, () => {
  const $roleAssessmentBasket = $('#role-assessment-basket');
  const params = window.location.search;

  if (params.includes('show_basket=true')) {
    const updatedParams = params.replace('show_basket=true', '');

    $roleAssessmentBasket.dropdown('toggle');

    window.setTimeout(() => {
      $roleAssessmentBasket.dropdown('toggle');
    }, 3000);

    window.history.replaceState({}, document.title, updatedParams);
  }
});
