import React, { Component } from 'react';
import Select from 'react-select';
import range from 'lodash/range';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import { FixedSizeList } from 'react-window';
import { getCSRFToken } from 'app/lib/utils/rest';

const EMPTY_OCCUPATION_ASSESSMENTS_COUNT = 20;

const selectionRange = (x, y) => {
  const ranges = range(x, y);
  return ranges.map(range => ({ value: range, label: range }));
};

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const height = 35;
  const initialScrollOffset = options.indexOf(value) * height;
  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialScrollOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};

const OccupationAssessment = ({ index, occupationOptions, onChange }) => (
  <li className="list-group-item d-flex align-items-center justify-content-between row">
    <div className="col-md-1 pr-0">{`Entry #${index}`}</div>
    <div className="col-md-7">
      <Select
        components={{ MenuList }}
        options={occupationOptions}
        placeholder="Occupation..."
        onChange={val => onChange({ index, ...val, ...{ type: 'occupation' } })}
      />
    </div>
    <div className="col-md-2">
      <Select
        name="ranking"
        options={selectionRange(1, 101)}
        placeholder="Ranking..."
        onChange={val => onChange({ index, ...val, ...{ type: 'ranking' } })}
      />
    </div>
    <div className="col-md-2">
      <Select
        name="bucket"
        options={selectionRange(0, 100)}
        placeholder="Bucket..."
        onChange={val => onChange({ index, ...val, ...{ type: 'bucket' } })}
      />
    </div>
  </li>
);

const OccupationAssessments = ({ occupationOptions, onChange }) => (
  <ul className="container list-group list-group-flush">
    {range(0, EMPTY_OCCUPATION_ASSESSMENTS_COUNT).map(index => (
      <OccupationAssessment
        index={index}
        occupationOptions={occupationOptions}
        key={index}
        onChange={onChange}
      />
    ))}
  </ul>
);

function reducer(currentInvalid, { uuid, bucket, ranking, index }) {
  const anyEmpty =
    uuid === undefined || bucket === undefined || ranking === undefined;
  const allEmpty =
    uuid === undefined && bucket === undefined && ranking === undefined;
  const someEmpty = anyEmpty && !allEmpty;
  if (someEmpty) {
    return [...currentInvalid, index];
  }
  return currentInvalid;
}

export default class OccupationAssessmentsForm extends Component {
  constructor(props) {
    super(props);
    const emptyOccupationAssessments = range(
      0,
      EMPTY_OCCUPATION_ASSESSMENTS_COUNT,
    );
    this.state = {
      occupationAssessments: emptyOccupationAssessments.map(index => ({
        index,
        uuid: undefined,
        ranking: undefined,
        bucket: undefined,
      })),
      formErrors: null,
    };
  }

  handleSubmit = () => {
    const { postUrl, redirectUrl } = this.props;
    const { occupationAssessments } = this.state;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        occupation_assessments: filter(
          occupationAssessments,
          o => o.uuid && o.ranking && o.bucket,
        ),
      }),
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      credentials: 'same-origin',
    };

    return fetch(postUrl, options).then(() =>
      window.location.assign(redirectUrl),
    );
  };

  validateEnteredData = () => {
    const { occupationAssessments } = this.state;

    const invalidEntries = occupationAssessments.reduce(reducer, []);

    if (invalidEntries.length > 0) {
      this.setState({
        formErrors: `These entries are invalid: ${invalidEntries.join(
          ', ',
        )}. (Occupation, rank, and bucket are required.)`,
      });
    } else {
      this.setState({ formErrors: null });
    }
  };

  onChange = option => {
    const { index, value, type } = option;
    const { occupationAssessments } = this.state;
    const clonedOccupationAssessments = cloneDeep(occupationAssessments);
    const occupationAssessment = clonedOccupationAssessments.find(
      occupationAssessment => occupationAssessment.index === index,
    );

    switch (type) {
      case 'occupation':
        occupationAssessment.uuid = value;
        break;
      case 'ranking':
        occupationAssessment.ranking = value;
        break;
      case 'bucket':
        occupationAssessment.bucket = value;
        break;
      default:
        break;
    }

    this.setState(
      { occupationAssessments: clonedOccupationAssessments },
      this.validateEnteredData,
    );
  };

  render() {
    const { occupationOptions } = this.props;
    const { formErrors } = this.state;
    return (
      <form>
        <OccupationAssessments
          occupationOptions={occupationOptions}
          onChange={this.onChange}
        />
        <div className="d-flex align-items-center justify-content-start p-3">
          <button
            type="button"
            className="btn btn-primary btn-wide"
            disabled={formErrors}
            onClick={this.handleSubmit}
          >
            Save
          </button>
          <div className="text-danger ml-5">{formErrors}</div>
        </div>
      </form>
    );
  }
}
