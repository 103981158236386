export const getEnvironmentSpecificCSRFToken = () => {
  const re = new RegExp(`X-IA-CSRF-Token=${window.location.hostname}([^;]+)`);
  const value = re.exec(document.cookie);
  return value !== null ? decodeURIComponent(value[1]) : null;
};

export const getCSRFToken = () => {
  const csrfToken = getEnvironmentSpecificCSRFToken();
  if (csrfToken) return csrfToken;

  const re = new RegExp('X-CSRF-Token=([^;]+)');
  const value = re.exec(document.cookie);
  return value !== null ? decodeURIComponent(value[1]) : null;
};

export function adminApi(path) {
  return `/admin/${path}`;
}

export function candidateApi(path) {
  return `/candidate/${path}`;
}

export function createResource(resource, payload) {
  const url = `${resource}.json`;
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  return fetch(url, options).then(r => r.json());
}

export function updateResource(resource, id, payload) {
  const url = `${resource}/${id}.json`;
  const options = {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  return fetch(url, options).then(r => r.json());
}

export function destroyResource(resource, id) {
  const url = `${resource}/${id}.json`;
  const options = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  return fetch(url, options);
}

export function getResource(resource, id) {
  const url = `${resource}/${id}.json`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  return fetch(url, options).then(r => r.json());
}

export function requestWithFetch(url, verb) {
  const options = {
    method: verb,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'same-origin',
  };

  return fetch(url, options);
}
