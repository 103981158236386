import includes from 'lodash/includes';
import get from 'lodash/get';
import { createElement } from 'react';
import { render } from 'react-dom';
import { jsonFromScript } from 'app/lib/common';
import logger from 'app/lib/utils/logger';
import { getRaven } from 'app/common/globals';
import '@babel/polyfill';

export default class Application {
  constructor() {
    this.config = window._config || {};
    this.apps = window._apps || {};
    this.components = window._components || {};
    this.translations = window._translations || {};
    this.featureFlags = window._featureFlags || {};
    this.assessmentFeatures = window._assessmentFeatures || {};
    this.plugins = window._plugins || [];
    this.locale = window._locale;
    this.raven = getRaven();

    // true if all assessments in the assignment have US as a supported country code
    this.assignmentLocaleUS = window._assignmentLocaleUS;

    this.reactOverviewSingleModuleOnly = window._reactOverviewSingleModuleOnly;
  }

  ready(callback) {
    this.setCurrentUser();
    this.invokeInitializers();
    this.mountGlobalComponents();
    if (callback) callback();
  }

  setCurrentUser() {
    const currentAdmin = jsonFromScript('#current-admin');
    if (currentAdmin) {
      this.currentAdmin = currentAdmin;
    }
  }

  invokeInitializers() {
    const initializersMap = window._initializersMap || {};
    const { namespace, action } = document.querySelector('body').dataset;
    const controllerActionPair = `${namespace}#${action}`;

    const initializeFunc = initializersMap[controllerActionPair];

    if (initializeFunc) {
      initializeFunc.call();
    }
  }

  mountGlobalComponents() {
    const reactClasses = document.querySelectorAll('[data-react-class]');
    for (let i = 0; i < reactClasses.length; i++) {
      const el = reactClasses[i];
      const componentName = el.dataset.reactClass;
      const componentProps = el.dataset.reactProps;
      if (this.components[componentName]) {
        render(
          createElement(
            this.components[componentName],
            JSON.parse(componentProps),
          ),
          el,
        );
      }
    }
  }

  set(keyOrObject, value = null) {
    if (typeof keyOrObject === 'object') {
      Object.keys(keyOrObject).forEach(key => {
        this.config[key] = keyOrObject[key];
      });
    } else {
      this.config[keyOrObject] = value;
    }
  }

  get(configKey) {
    return get(this.config, configKey);
  }

  registerComponents(components) {
    this.components = { ...this.components, ...components };
  }

  registerPlugins(plugins) {
    plugins.forEach(plugin => {
      try {
        if (!plugin.pluginName) return logger.warn('Plugin name missing');
        if (includes(this.plugins, plugin.pluginName)) {
          return logger.warn(
            `Plugin already initialized: ${plugin.pluginName}`,
          );
        }

        // eslint-disable-next-line new-cap, no-new
        new plugin();
        this.plugins.push(plugin.pluginName);
        logger.debug(`Initializing plugin ${plugin.pluginName}`);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('PLUGIN ERROR', e);
      }
    });
  }
}
