import logger from './utils/logger';

/**
 * @param {String} selector
 * @returns {Object} The parsed JSON object
 */

export function jsonFromScript(selector) {
  const jsonNode = document.querySelector(selector);

  if (jsonNode) {
    try {
      return JSON.parse(jsonNode.innerHTML);
    } catch (e) {
      // alert error to user
      // eslint-disable-next-line no-console
      console.warn('Error parsing JSON', e);
    }
  }
}

export function definePlugin(name, initFunc) {
  const wrapperFunc = () => {
    try {
      initFunc();
      // logger.debug(`Initialized plugin: ${name}`)
    } catch (e) {
      logger.error(`Error while initialzing plugin: ${name}`);
      logger.exception(e);
    }
  };

  $(wrapperFunc);
}

export function plugin(name) {
  return function decorator(target) {
    // eslint-disable-next-line no-param-reassign
    target.pluginName = name;
    return target;
  };
}

export function notify(message, options = {}) {
  $.notify.addStyle('bootstrap', {
    html: `
      <div>
      <div class='title' data-notify-html='title'/>
      <i class="material-icons text-white close">close</i>
      </div>
    `,
  });

  const autoHideDelay = options.className === 'danger' ? 10000 : 4000;

  $.notify(
    { title: message },
    {
      position: 'top', // can't use 'top center' here; it is not strictly centered.
      style: 'bootstrap',
      className: 'info',
      autoHideDelay,
      ...options,
    },
  );
}

// export to window for testing
window.notify = notify;

export function initializeOn(route, initializerFunc) {
  if (!window._initializersMap) window._initializersMap = {};

  const controllerActionKey = `${route.controller}#${route.action}`;

  window._initializersMap[controllerActionKey] = initializerFunc;
}
