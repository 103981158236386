import get from 'lodash/get';
import template from 'lodash/template';
import { getAppInstance } from 'app/common/globals';

const I18N_TEMPLATE_REGEX = /%{([\s\S]+?)}/g;
export const MISSING_TRANSLATION_TEXT = 'MISSING_TRANSLATION';
export const DEFAULT_LOCALE = 'en';

export const getLocaleFromUrl = () => {
  try {
    const url = new URL(get(global, 'window.location.href', ''));

    if (url && url.searchParams) {
      return url.searchParams.get('locale');
    }
  } catch (e) {
    // ignore error
  }

  return null;
};

const getLocaleFromBrowserSettings = () => {
  return get(global, 'window.navigator.language');
};

const getRawLocale = () => {
  return getLocaleFromUrl() ?? getLocaleFromBrowserSettings();
};

let availableLocales = null;
export const getAvailableLocales = () => {
  if (availableLocales) return availableLocales;
  availableLocales = JSON.parse(process.env.AVAILABLE_LOCALES);

  return availableLocales;
};

export const isLocaleAvailable = locale => {
  if (!locale) return false;

  const normalizedLocale = locale.toLowerCase();
  return getAvailableLocales().indexOf(normalizedLocale) > -1;
};

// given a locale string, find the correct fallback locale
// eg if locale is 'fr-fr', the correct fallback is
// 'fr-fr' (identity operation) if that is a supported locale
// 'fr' if we support that language but not that specific country code
// 'en' if we do not support the language at all
export const getFallbackLocale = locale => {
  if (!locale) {
    return DEFAULT_LOCALE;
  }

  const normalizedLocale = locale.toLowerCase();
  if (isLocaleAvailable(normalizedLocale)) {
    return normalizedLocale;
  }

  const localeWithoutRegion = normalizedLocale.slice(0, 2);
  if (isLocaleAvailable(localeWithoutRegion)) {
    return localeWithoutRegion;
  }

  return DEFAULT_LOCALE;
};

export const getLocale = () => {
  // Note: if locale is fully supported, getFallbackLocale returns it unchanged
  return getFallbackLocale(getRawLocale());
};

export const isRegion = regionCode => {
  const normalizedRegion = regionCode.toLowerCase();
  const language = get(
    global,
    'window.navigator.language',
    DEFAULT_LOCALE,
  ).toLowerCase();
  if (normalizedRegion === language) return true;

  // special case to handle 'us' defaulting to 'en' for american english
  if (normalizedRegion === 'us' && language === 'en') return true;

  return language.slice(3, 5) === normalizedRegion;
};

export const loadLocaleFiles = async () => {
  /*
   * TODO: get rid of this and its calls
   *
   * In !3744, we cut out the contents of this function, since translations are now
   * loaded entirely on the backend. However, the backend is, at time-of-MR, loading
   * every translations for every path. We can definitely shrink that list, but it was
   * fiddly to figure out what. This function still exists, then, for two reasons:
   * 1) kept !3744 smaller and tidier, since this is used by all the users of AppDataLoader,
   *    and there's a lot of those
   * 2) when looking into shrinking the "put every translations everywhere" calls, it
   *    might be helpful to still have the calls to this function available for inspection
   */
};

const _t = (path, templateVars = {}, options = {}) => {
  const { translations, locale, raven } = getAppInstance();
  let translatedString = get(translations, path);

  if (translatedString === undefined) {
    if (options.returnOnMissingTranslation) {
      return;
    }
    raven.captureMessage(`Translation missing for ${locale}.${path}`);
    translatedString = MISSING_TRANSLATION_TEXT;
  }

  if (!templateVars || !Object.keys(templateVars).length) {
    return translatedString;
  }

  return template(translatedString, { interpolate: I18N_TEMPLATE_REGEX })(
    templateVars,
  );
};

/**
 * like default (_t), but returns undefined when translation text isn't found.
 * this is useful when you need to do some processing on undefined translations.
 */
export const translate = (path, templateVars = {}) => {
  return _t(path, templateVars, { returnOnMissingTranslation: true });
};

export default _t;
