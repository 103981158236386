import React from 'react';

const NewStepCard = ({
  title,
  description,
  stepType,
  onClick,
  authorized = true,
  disabled,
  newAssessment,
  icon,
}) => {
  if (!authorized) return null;
  const buttonText = newAssessment ? 'Create' : 'Add';

  return (
    /* eslint-disable react/no-danger */
    <div className="card-new-step">
      <div className="card card-body m-2 justify-content-between">
        <div>
          <div className="d-flex justify-content-center">
            <i className="material-icons large text-muted">{icon}</i>
          </div>

          <h3 className="mb-2">{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div>
          <button
            className="btn btn-primary"
            disabled={disabled}
            onClick={() => onClick(stepType)}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
    /* eslint-enable react/no-danger */
  );
};

export default NewStepCard;
