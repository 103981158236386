// Disabling eslint uxqual rules as part of ASSESSAX-3087, IFL cleanup
/* eslint-disable @indeed/uxqual/no-html-tags, @indeed/uxqual/no-class-names */
import React, { Component } from 'react';
import Quill from 'quill';
import Delta from 'quill-delta';
import has from 'lodash/has';
import pick from 'lodash/pick';
import remove from 'lodash/remove';

const allowedAttributes = ['bold', 'italic', 'underline', 'list', 'link'];

const blankLineRegex = /<p><br><\/p>/g;

const sanitizeStyles = (node, delta) => {
  let ops = delta.ops.slice();

  remove(ops, op => {
    return has(op, 'insert.image');
  });

  ops = ops.map(op => {
    // eslint-disable-next-line no-param-reassign
    op.attributes = pick(op.attributes, allowedAttributes);
    return op;
  });

  return new Delta(ops);
};

export default class Wysiwyg extends Component {
  componentDidMount() {
    this.editor = new Quill(this.el, {
      theme: 'snow',
      placeholder: this.props.placeholder || '',
      modules: this.props.modules || {
        toolbar: this.props.toolbarOptions || {},
        clipboard: {
          matchers: [[Node.ELEMENT_NODE, sanitizeStyles]],
        },
      },
      readOnly: this.props.readOnly,
    });

    this.editor.on('text-change', () => {
      const value = this.el.querySelector('.ql-editor').innerHTML;
      this.props.onChange(value);
    });

    this.editor.on('selection-change', range => {
      const value = this.el.querySelector('.ql-editor').innerHTML;
      const trimValue = value.replace(blankLineRegex, '');
      // range = null indicates loss of focus, i.e. blur
      // https://quilljs.com/docs/api/#selection-change
      if (!range) {
        this.props.onChange(trimValue);
        this.updateHTML(trimValue);
      }
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) {
      this.updateHTML(newProps.value);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  updateHTML(htmlValue) {
    // it's necessary to set the contents this way, otherwise quill steals
    // focus from all other inputs every time 'dangerouslyPasteHTML' is called.
    // https://github.com/quilljs/quill/issues/2156
    const delta = this.editor.clipboard.convert(htmlValue);
    this.editor.setContents(delta);
  }

  render() {
    return (
      <div className="wysiwyg-wrapper">
        <div
          ref={el => {
            this.el = el;
          }}
          className="wysiwyg-editor"
          dangerouslySetInnerHTML={{ __html: this.props.value }}
        />
      </div>
    );
  }
}
