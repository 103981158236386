import noop from 'lodash/noop';
import React, { Component } from 'react';
import {
  Flex,
  IndeedThemeProvider,
  Box,
  Button,
  ProgressBar,
  Text,
} from '@indeed/ifl-components';
import { secondsToTime } from 'app/common/utils';
import {
  MEDIA_ICON_STATE_MAP,
  MEDIA_INITIAL,
  MEDIA_ENDED,
  MEDIA_PAUSED,
  MEDIA_PLAYING,
} from 'app/common/constants';

/*
  eslint-disable
  jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/no-typos
*/

/*
  react/no-typos is disabled because there appears to be a bug with the linter:
    https://github.com/yannickcr/eslint-plugin-react/issues/1389
 */

export const WistiaControls = ({
  onPlay,
  onPause,
  percentage,
  duration,
  status,
  ready,
}) => {
  const ButtonIcon = MEDIA_ICON_STATE_MAP[status];
  const buttonFn = status === MEDIA_PLAYING ? onPause : onPlay;

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Button
        type="button"
        disabled={!ready}
        onClick={buttonFn}
        variant="tertiary"
        iconBefore={<ButtonIcon />}
        data-testid="play-button"
        sx={{ borderRadius: 'none', flexBasis: '16.67%', border: 'none' }}
      />
      <ProgressBar value={percentage} sx={{ mx: 4, borderRadius: 'full' }} />
      <Text
        data-testid="controls-duration"
        sx={{ pr: 4, color: 'neutral.800', fontWeight: 'bold', fontSize: 1 }}
      >
        {secondsToTime(duration)}
      </Text>
    </Flex>
  );
};

/**
 * WistiaPlayer
 * the default props `options` object disable all interactivity with wistia's player.
 * however, wistia's api offers no way to disable pausing, when a click action
 * is detected on the video element. the hacky solution to this problem is to
 * place a transparent div over the entire video (using `disableClickPause`)
 * where we add our own optional onClick.
 */
export default class WistiaPlayer extends Component {
  defaultState = {
    status: MEDIA_INITIAL,
    ready: false,
    percentage: 0,
    duration: 0,
    uniqueContainerId: null,
  };

  static defaultProps = {
    width: '30rem',
    height: '22.5rem',
    onEnd: noop,
    onTimeChange: noop,
    onClick: noop, // disableClickPause must be true for onClick to work
    onPlay: noop,
    disabled: false,
    sx: {},
    options: {
      videoFoam: true,
      playButton: false,
      playbackRateControl: false,
      qualityControl: false,
      settingsControl: false,
      fullscreenButton: false,
      volumeControl: false,
      chromeless: true,
      controlsVisibleOnLoad: false,
      playbar: true,
      disableClickPause: true, // add overlay div
      volume: 1.0,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState;

    this.onClickOverlay = this.onClickOverlay.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.initWistiaVideo(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.wistiaId !== this.props.wistiaId) {
      this.resetState();
      this.unbindWistiaEvents();
      this.initWistiaVideo(newProps);
    }

    if (newProps.disabled && this.state.status === MEDIA_PLAYING) {
      this.stopPlayback();
    }
  }

  componentWillUnmount() {
    this.isCancelled = true;
    this.unbindWistiaEvents();
  }

  onClickOverlay(event) {
    if (this.isVideoUnbound() || this.props.disabled) return;

    this.props.onClick(event);
    this.video.play();
  }

  bindWistiaEvents = video => {
    if (this.isCancelled) return;

    const duration = Math.floor(video.duration());
    this.video = video;
    if (!video && this.props.onError) {
      this.props.onError('WISTIA_PLAYER_ERROR');
    }
    video.bind('play', this.handlePlay);
    video.bind('pause', this.handlePause);
    video.bind('end', this.handleEnd);
    video.bind('timechange', this.updatePercentage);
    this.setState({ ready: true, duration });
  };

  stopPlayback = () => {
    if (this.isVideoUnbound()) return;
    this.video.time(0);
    this.handlePause();
  };

  handlePlay = () => {
    if (this.isVideoUnbound()) return;

    if (this.state.percentage === 100) {
      this.updatePercentage(0);
    }
    this.setState({ status: MEDIA_PLAYING });
    this.video.play();
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  handlePause = () => {
    if (this.isVideoUnbound()) return;

    this.setState({ status: MEDIA_PAUSED });
    this.video.pause();
  };

  handleEnd = () => {
    this.updatePercentage(0, 1);
    this.setState({ status: MEDIA_ENDED });
    if (this.props.onEnd) {
      this.props.onEnd();
    }
  };

  updatePercentage = time => {
    if (this.isVideoUnbound()) return;

    if (this.props.onTimeChange) {
      this.props.onTimeChange();
    }
    const percentage = Math.min(100, (time / this.state.duration) * 100);
    this.setState({ percentage });
  };

  isVideoUnbound = () => {
    return this.isCancelled || !this.video;
  };

  resetState = () => {
    this.setState(this.defaultState);
  };

  unbindWistiaEvents() {
    const { video } = this;
    if (!video) return;

    video.unbind('play', this.handlePlay);
    video.unbind('pause', this.handlePause);
    video.unbind('end', this.handleEnd);
    video.unbind('timechange', this.updatePercentage);

    this.video.remove();
    this.video = null;
  }

  initWistiaVideo(props) {
    const { wistiaId, options } = props;

    this.video = null;
    this.isCancelled = false;

    /**
     * uniqueContainerId
     * this is necessary for when more than one of the same video
     * is on the page at once (video interview)
     */
    const uniqueId = Math.random().toString(20).substr(2, 20);
    const uniqueContainerId = `${wistiaId}_${uniqueId}`;

    this.setState({ uniqueContainerId });

    window._wq = window._wq || [];
    window._wq.push({
      id: uniqueContainerId,
      options,
      onReady: this.bindWistiaEvents,
    });
  }

  render() {
    const {
      wistiaId,
      width,
      height,
      options: { disableClickPause },
      playButton,
      useControls,
      disabled,
      sx,
    } = this.props;
    const { percentage, duration, status, ready, uniqueContainerId } =
      this.state;
    const overlayDisabledSx = disabled
      ? { backgroundColor: 'neutral.900', opacity: 0.9 }
      : {};

    return (
      <IndeedThemeProvider>
        <Box
          key={wistiaId}
          sx={{
            position: 'relative',
            justifyContent: 'center',
            maxWidth: '70%',
            border: '1px solid',
            borderColor: 'neutral.400',
            borderRadius: 'sm',
            mb: 4,
            ...sx,
          }}
        >
          <Flex
            id={uniqueContainerId}
            // classes used to load video on container
            // eslint-disable-next-line @indeed/uxqual/no-class-names
            className={`wistia_embed wistia_async_${wistiaId}`}
            sx={{ width, height }}
          />
          {disableClickPause && (
            <Flex
              data-testid="wistia-overlay"
              onClick={this.onClickOverlay}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                ...overlayDisabledSx,
              }}
            >
              {!disabled && status !== MEDIA_PLAYING && playButton}
            </Flex>
          )}
          {useControls && (
            <WistiaControls
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              percentage={percentage}
              duration={duration}
              status={status}
              ready={ready}
            />
          )}
        </Box>
      </IndeedThemeProvider>
    );
  }
}
