import React, { Component } from 'react';
import Select from 'react-select';
import { get } from 'app/common/api';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

const acceptMultipleOrgs = ['content_team_collaborator', 'assessments_author'];
const acceptNoOrgs = ['customer_support_agent', 'indeed_hire_specialist'];

export default class AspenPermissions extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearch = debounce(this.handleSearch, 250);
    this.handleChange = this.handleChange.bind(this);
    this.titleCase = this.titleCase.bind(this);
    this.formatSearchResultItem = this.formatSearchResultItem.bind(this);

    this.state = {
      pickedRole: props.pickedRole || 'content_team_collaborator',
      organizationsSearchResults: [],
      organizationsMultiSelect: acceptMultipleOrgs.includes(props.pickedRole),
      organizationsDisabled: acceptNoOrgs.includes(props.pickedRole),
      organizationToHouseTheNewUser: props.defaultOrganization,
      organizationsCurrentlySelected: isEmpty(props.persistedOrganizations)
        ? props.defaultOrganization
        : props.persistedOrganizations,
      isLoading: false,
    };
  }

  handleChange(value) {
    const { defaultOrganization } = this.props;

    if (Array.isArray(value) && value.length > 0) {
      this.setState({
        organizationToHouseTheNewUser: value[0],
        organizationsCurrentlySelected: value,
      });
    } else if (isEmpty(value)) {
      this.setState({
        organizationToHouseTheNewUser: defaultOrganization,
        organizationsCurrentlySelected: defaultOrganization,
      });
    } else {
      this.setState({
        organizationToHouseTheNewUser: value,
        organizationsCurrentlySelected: value,
      });
    }
  }

  handleSearch(searchTerm) {
    this.setState({ isLoading: true });

    get(`/admin/organization/search?term=${searchTerm}`).then(
      organizationsSearchResults => {
        this.setState({ organizationsSearchResults, isLoading: false });
      },
    );
  }

  handleClick(event) {
    const pickedRole = event.currentTarget.value;
    this.setState({ pickedRole });

    if (acceptNoOrgs.includes(pickedRole)) {
      this.setState({
        organizationsMultiSelect: false,
        organizationsDisabled: true,
      });
    } else if (acceptMultipleOrgs.includes(pickedRole)) {
      this.setState({
        organizationsMultiSelect: true,
        organizationsDisabled: false,
      });
    }
  }

  titleCase(str) {
    const splitted = str.toLowerCase().split('_');

    for (let i = 0; i < splitted.length; i++) {
      splitted[i] = splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1);
    }
    return splitted.join(' ');
  }

  formatSearchResultItem({ id, name }) {
    return (
      <div>
        <span>
          <span className="text-muted small">{id} </span>
          {name}
        </span>
      </div>
    );
  }

  render() {
    const {
      pickedRole,
      organizationsMultiSelect,
      organizationsDisabled,
      organizationsSearchResults,
      organizationToHouseTheNewUser,
      organizationsCurrentlySelected,
      isLoading,
    } = this.state;

    const { systemRoles, isNewAdmin, adminOrganization } = this.props;

    return (
      <div>
        <hr />
        <h2 className="h3">Special Permissions</h2>
        <p className="small text-muted">
          Please refer to the
          <a
            href="https://docs.google.com/spreadsheets/d/1iN-oX14aWj_0nALj7VFJGo0hkIKA-O6lsAZM5qLQD5I/edit#gid=1139499772"
            target="_blank"
          >
            {' '}
            permissions table
          </a>{' '}
          if you are unsure.
        </p>
        <div className="row">
          <div className="col">
            <div className="d-flex flex-column pl-3">
              {systemRoles.map(role => {
                return (
                  <label key={`keyLabel-${role}`}>
                    <input
                      type="radio"
                      name="role"
                      key={`keyRole-${role}`}
                      checked={role === pickedRole}
                      value={role}
                      onChange={this.handleClick}
                      className="px-3 py-2"
                    />{' '}
                    {this.titleCase(role)}
                  </label>
                );
              })}
            </div>
          </div>

          <div className="col">
            <h5 className="h5">Organization(s)</h5>

            <Select
              isLoading={isLoading}
              name="organizations[]"
              isMulti={organizationsMultiSelect}
              options={organizationsSearchResults}
              placeholder="Search..."
              onInputChange={this.handleSearch}
              onChange={this.handleChange}
              isDisabled={organizationsDisabled}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              defaultValue={organizationsCurrentlySelected}
              value={organizationsCurrentlySelected}
              formatOptionLabel={this.formatSearchResultItem}
            />
            <div className="text-muted small mt-2">
              <i className="material-icons mr-1">info</i>
              {isNewAdmin ? (
                <div>
                  This new user will be created in the{' '}
                  <strong>
                    {organizationToHouseTheNewUser.name} (
                    {organizationToHouseTheNewUser.id})
                  </strong>{' '}
                  organization.
                </div>
              ) : (
                <div>
                  This user is part of the{' '}
                  <strong>
                    {' '}
                    {adminOrganization.name} ({adminOrganization.id}){' '}
                  </strong>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
