import { plugin } from 'app/lib/common';

export default plugin('notifications')(
  class {
    constructor() {
      // Make rails alerts hide on click
      $('.notify-rails-container').click(this.dismissNotification);
    }

    dismissNotification() {
      $(this).animate({ top: '-9.375rem' }, 500);
    }
  },
);
