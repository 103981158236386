import React, { Component } from 'react';
import truncate from 'html-truncate';

export default class RichTextExpander extends Component {
  state = {
    open: false,
  };

  expand = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    /* eslint-disable react/no-danger */
    if (truncate(this.props.text, 200).length < this.props.text.length) {
      if (this.state.open) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
            <button className="btn btn-link" onClick={this.close}>
              View less{' '}
              <i className="toggle-icon material-icons">keyboard_arrow_up</i>
            </button>
          </div>
        );
      }
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: truncate(this.props.text, 200, { keepImageTag: true }),
            }}
          />
          <button className="btn btn-link" onClick={this.expand}>
            View Full Question{' '}
            <i className="toggle-icon material-icons">keyboard_arrow_down</i>
          </button>
        </div>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: this.props.text }} />;
    /* eslint-enable react/no-danger */
  }
}
