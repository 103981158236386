/* eslint-disable no-console */
export default {
  exception(e) {
    if (window.Raven && Raven.captureException) Raven.captureException(e);
    console.error(e);
  },

  error(msg) {
    if (window.Raven) Raven.captureMessage(msg);
    console.error(msg);
  },

  info(...args) {
    console.log.apply(null, ['[I]', ...args]);
  },

  debug(...args) {
    console.log.apply(null, ['[D]', ...args]);
  },

  warn(...args) {
    const warn = console.warn || console.log;
    warn.apply(null, ['[W]', ...args]);
  },
};

const log = (...args) => {
  console.log(...args);
};

export { log };
