import React, { Component } from 'react';
import ImageMapImage from 'app/components/ImageMapImage';
import { CLICK_WIDTH } from 'app/apps/Reform/components/questions/ImageMapField';
import { Box, Button, Flex, IndeedThemeProvider } from '@indeed/ifl-components';
import { Modal, ModalBody } from '@indeed/ipl-modal';

const ImageMapClicks = ({ clicks, getCoordinates }) =>
  clicks.map(click => {
    const position = getCoordinates(click.x_coordinate, click.y_coordinate);
    const positionAdjustment = CLICK_WIDTH / 2;
    return (
      <Box
        key={click.id}
        data-testid="image-map-preview-click"
        sx={{
          position: 'absolute',
          top: position.top - positionAdjustment,
          left: position.left - positionAdjustment,
          display: 'block',
          width: '1.375rem',
          height: '1.375rem',
          borderRadius: 'full',
          backgroundColor: 'primary.400',
          border: '2px solid',
          borderColor: 'neutral.0',
        }}
      />
    );
  });

const ImageMapHotspots = ({ hotspots, getCoordinates }) =>
  hotspots.map(hotspot => {
    const position = getCoordinates(
      hotspot.image_map_hotspot_origin_x,
      hotspot.image_map_hotspot_origin_y,
      hotspot.image_map_hotspot_width,
      hotspot.image_map_hotspot_height,
    );

    return (
      <Box
        key={hotspot.id}
        data-testid="image-map-preview-hotspot"
        sx={{
          ...position,
          border: '2px solid',
          position: 'absolute',
          borderColor: 'primary.800',
        }}
      />
    );
  });

class ImageMapAnswerPreview extends Component {
  state = { isModalOpen: false };

  handleToggleModal = () => {
    this.setState(prev => ({ isModalOpen: !prev.isModalOpen }));
  };

  handleExitModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { clicks, image, hotspots } = this.props;
    return (
      <IndeedThemeProvider>
        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Button type="button" onClick={this.handleToggleModal} sx={{ mt: 2 }}>
            View Answer
          </Button>
          <Modal
            isOpen={this.state.isModalOpen}
            onExit={this.handleExitModal}
            size="lg"
            title="View Answer"
            sx={{ maxWidth: '56.25rem' }}
          >
            <ModalBody
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <ImageMapImage image={image}>
                {getCoordinates => {
                  return (
                    <>
                      <ImageMapClicks
                        clicks={clicks}
                        getCoordinates={getCoordinates}
                      />
                      <ImageMapHotspots
                        hotspots={hotspots}
                        getCoordinates={getCoordinates}
                      />
                    </>
                  );
                }}
              </ImageMapImage>
            </ModalBody>
          </Modal>
        </Flex>
      </IndeedThemeProvider>
    );
  }
}

export default ImageMapAnswerPreview;
