import React from 'react';
import classNames from 'classnames';
import { getIconCode } from './materialIconsMapping';

const MaterialIcon = ({ children, className, ...rest }) => {
  const iconClassName = classNames('material-icons', className);
  const iconCode = getIconCode(children);

  if (iconCode) {
    return (
      <i
        {...rest}
        className={iconClassName}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: iconCode }}
      />
    );
  }

  return (
    <i {...rest} className={iconClassName}>
      {children}
    </i>
  );
};

export const DragHandle = ({ dragHandle, ...rest }) => {
  return dragHandle(
    <div>
      <MaterialIcon {...rest}>drag_handle</MaterialIcon>
    </div>,
  );
};

export default MaterialIcon;
