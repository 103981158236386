import Play from '@indeed/ifl-icons/Play';
import Pause from '@indeed/ifl-icons/Pause';
import Reset from '@indeed/ifl-icons/Reset';
import Renew from '@indeed/ifl-icons/Renew';

// MEDIA STATES
export const MEDIA_INITIAL = 'INITIAL';
export const MEDIA_PLAYING = 'PLAYING';
export const MEDIA_PAUSED = 'PAUSED';
export const MEDIA_ENDED = 'ENDED';
export const MEDIA_LOADING = 'LOADING';

export const MEDIA_ICON_STATE_MAP = {
  [MEDIA_INITIAL]: Play,
  [MEDIA_PLAYING]: Pause,
  [MEDIA_PAUSED]: Play,
  [MEDIA_ENDED]: Reset,
  [MEDIA_LOADING]: Renew,
};

export const STEP_FORM_LAYOUT_SINGLE_QUESTION = 'single_question_layout';
export const STEP_FORM_LAYOUT_STEP_TIMER = 'step_timer';

export const COUNTRY_FLAGS_PATH = 'url("/country_flags/flags.png")';
