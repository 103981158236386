import uniqueId from 'lodash/uniqueId';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { patch } from 'app/common/api';
import React, { Component } from 'react';
import { jsonFromScript, notify } from 'app/lib/common';
import MaterialIcon from 'app/components/MaterialIcon';
import Action from './Action';
import Condition from './Condition';

export default class TriggerConditions extends Component {
  constructor(props) {
    super(props);
    const trigger = jsonFromScript('#trigger-json');
    this.state = {
      trigger,
      conditions: trigger.trigger_conditions
        ? cloneDeep(trigger.trigger_conditions)
        : [],
      actions: trigger.trigger_actions
        ? cloneDeep(trigger.trigger_actions)
        : [],
    };
  }

  handleAddNewAllCondition = () => {
    const newState = this.state.conditions;
    newState.push({ any_all: 'all', _id: uniqueId('all_') });
    this.setState({ conditions: newState });
  };

  handleAddNewAnyCondition = () => {
    const newState = this.state.conditions;
    newState.push({ any_all: 'any', _id: uniqueId('any_') });
    this.setState({ conditions: newState });
  };

  handleAddNewAction = () => {
    const newState = this.state.actions;
    newState.push({ _id: uniqueId('action_') });
    this.setState({ actions: newState });
  };

  handleInputChange = (id, value) => {
    const newState = this.state.conditions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], value };
    this.setState({ conditions: newState });
  };

  handleConditionTypeChange = (id, type) => {
    const newState = this.state.conditions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], type };
    this.setState({ conditions: newState });
  };

  handleComparisonChange = (id, operator) => {
    const newState = this.state.conditions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], operator };
    this.setState({ conditions: newState });
  };

  handleActionTypeChange = (id, type) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], type };
    this.setState({ actions: newState });
  };

  handleRecipientTypeChange = (id, recipientType) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], recipient_type: recipientType };
    this.setState({ actions: newState });
  };

  handleBodyTypeChange = (id, bodyType) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], body_type: bodyType };
    this.setState({ actions: newState });
  };

  handleBodyChange = (id, body) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], body };
    this.setState({ actions: newState });
  };

  handleURLChange = (id, url) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], url };
    this.setState({ actions: newState });
  };

  handleEmailChange = (id, email) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], email };
    this.setState({ actions: newState });
  };

  handleCCChange = (id, cc) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], cc };
    this.setState({ actions: newState });
  };

  handleSubjectChange = (id, subject) => {
    const newState = this.state.actions;
    const index = findIndex(newState, o => o._id === id || o.id === id);
    newState[index] = { ...newState[index], subject };
    this.setState({ actions: newState });
  };

  save = () => {
    const hasValidationErrors = data =>
      data.errors && !isEmpty(data.errors.full_messages);

    patch(`/admin/triggers/${this.state.trigger.id}`, {
      trigger: {
        ...this.state.trigger,
        trigger_actions_attributes: {
          ...this.state.actions,
        },
        trigger_conditions_attributes: {
          ...this.state.conditions,
        },
      },
    }).then(data => {
      if (hasValidationErrors(data)) {
        notify(`Failed to save: ${data.errors.full_messages}`, {
          className: 'danger',
        });
      } else {
        notify('Trigger was updated.');
      }
    });
  };

  deleteCondition = id => {
    const index = findIndex(
      this.state.conditions,
      o => o._id === id || o.id === id,
    );
    const objectToDelete = this.state.conditions[index];
    objectToDelete._destroy = true;
    const newState = this.state.conditions;
    newState[index] = objectToDelete;
    patch(`/admin/triggers/${this.state.trigger.id}`, {
      trigger: {
        trigger_actions_attributes: {
          ...this.state.actions,
        },
        trigger_conditions_attributes: {
          ...this.state.conditions,
        },
      },
    }).then(() => {
      newState.splice(index, 1);
      this.setState({ conditions: newState });
    });
  };

  deleteAction = id => {
    const index = findIndex(
      this.state.actions,
      o => o._id === id || o.id === id,
    );
    const objectToDelete = this.state.actions[index];
    objectToDelete._destroy = true;
    const newState = this.state.actions;
    newState[index] = objectToDelete;
    patch(`/admin/triggers/${this.state.trigger.id}`, {
      trigger: {
        trigger_actions_attributes: {
          ...this.state.actions,
        },
        trigger_conditions_attributes: {
          ...this.state.conditions,
        },
      },
    }).then(() => {
      newState.splice(index, 1);
      this.setState({ actions: newState });
    });
  };

  render() {
    const { disabled } = this.props;
    return (
      <div>
        <div className="card card-body mt-3">
          <h3>All of these conditions must match</h3>
          <form>
            {this.state.conditions
              .filter(condition => condition.any_all === 'all')
              .map(data => {
                const id = data._id || data.id;
                return (
                  <Condition
                    data={data}
                    key={id}
                    handleConditionTypeChange={e =>
                      this.handleConditionTypeChange(id, e.target.value)
                    }
                    handleComparisonChange={e =>
                      this.handleComparisonChange(id, e.target.value)
                    }
                    onInputChange={e =>
                      this.handleInputChange(id, e.target.value)
                    }
                    handleOnDelete={() => this.deleteCondition(id)}
                    disabled={disabled}
                  />
                );
              })}
          </form>
          {!disabled && (
            <div>
              <button
                type="button"
                className="btn btn-secondary btn-wide mt-3"
                onClick={this.handleAddNewAllCondition}
              >
                <MaterialIcon>add</MaterialIcon> Add a new condition
              </button>
            </div>
          )}
        </div>
        <div className="card card-body mt-3">
          <h3>Any of these conditions must match</h3>
          <form>
            {this.state.conditions
              .filter(condition => condition.any_all === 'any')
              .map(data => {
                const id = data._id || data.id;
                return (
                  <Condition
                    data={data}
                    key={id}
                    handleConditionTypeChange={e =>
                      this.handleConditionTypeChange(id, e.target.value)
                    }
                    handleComparisonChange={e =>
                      this.handleComparisonChange(id, e.target.value)
                    }
                    onInputChange={e =>
                      this.handleInputChange(id, e.target.value)
                    }
                    handleOnDelete={() => this.deleteCondition(id)}
                    disabled={disabled}
                  />
                );
              })}
          </form>
          {!disabled && (
            <div>
              <button
                type="button"
                className="btn btn-secondary btn-wide mt-3"
                onClick={this.handleAddNewAnyCondition}
              >
                <MaterialIcon>add</MaterialIcon> Add a new condition
              </button>
            </div>
          )}
        </div>
        <div className="card card-body mt-3">
          <h3>Perform these actions</h3>
          <form>
            {this.state.actions.map(action => {
              const id = action._id || action.id;
              return (
                <Action
                  data={action}
                  key={id}
                  handleBodyTypeChange={bodyType =>
                    this.handleBodyTypeChange(id, bodyType)
                  }
                  handleWebHookBodyChange={event =>
                    this.handleBodyChange(id, event.target.value)
                  }
                  handleBodyChange={value => this.handleBodyChange(id, value)}
                  handleActionTypeChange={e =>
                    this.handleActionTypeChange(id, e.target.value)
                  }
                  handleURLChange={e =>
                    this.handleURLChange(id, e.target.value)
                  }
                  handleEmailChange={event =>
                    this.handleEmailChange(id, event.target.value)
                  }
                  handleCCChange={event =>
                    this.handleCCChange(id, event.target.value)
                  }
                  handleSubjectChange={event =>
                    this.handleSubjectChange(id, event.target.value)
                  }
                  handleOnDelete={() => this.deleteAction(id)}
                  handleRecipientTypeChange={e =>
                    this.handleRecipientTypeChange(id, e.target.value)
                  }
                  disabled={disabled}
                />
              );
            })}
          </form>
          {!disabled && (
            <div>
              <button
                type="button"
                onClick={this.handleAddNewAction}
                className="btn btn-secondary btn-wide mt-3"
              >
                <MaterialIcon>add</MaterialIcon> Add a new action
              </button>
            </div>
          )}
        </div>
        {!disabled && (
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-primary btn-wide mt-3"
              onClick={this.save}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  }
}
