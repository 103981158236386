import React from 'react';
import NewStepCard from 'app/components/admin/NewStepCard';
import SuperAdmin from 'app/apps/Builder/components/shared/superadmin';

const NewStepCards = ({
  onClick,
  disabled,
  newAssessment = false,
  authorizations,
}) => {
  return (
    <div className="d-flex flex-wrap">
      <NewStepCard
        newAssessment={newAssessment}
        title="Standard Form Layout"
        description="The most versatile layout, one question per page."
        stepType="reform"
        icon="list"
        onClick={onClick}
        disabled={disabled}
      />
      <NewStepCard
        newAssessment={newAssessment}
        authorized={authorizations.can_create_typing_tests}
        title="Typing"
        description="Set custom typing prompts to test real-world typing skills."
        stepType="typing_test"
        icon="keyboard"
        onClick={onClick}
        disabled={disabled}
      />

      <NewStepCard
        newAssessment={newAssessment}
        title="Text Page"
        description="A preliminary page for instructions and other types of textual information."
        stepType="interstitial"
        icon="receipt"
        onClick={onClick}
        disabled={disabled}
      />

      <NewStepCard
        newAssessment={newAssessment}
        authorized={authorizations.can_create_audio_interviews}
        title="Audio Interview"
        description="Pre-recorded audio questions on desktop or mobile devices."
        stepType="audio_interview"
        icon="phone"
        onClick={onClick}
        disabled={disabled}
      />

      <NewStepCard
        newAssessment={newAssessment}
        authorized={authorizations.can_create_video_interviews}
        title="Video Interview"
        description="Pre-recorded video questions or textual prompts on desktop or mobile devices."
        stepType="video_interview"
        icon="video_call"
        onClick={onClick}
        disabled={disabled}
      />

      <SuperAdmin>
        <div className="container-fluid">
          <div
            className="row pl-3"
            style={{ borderBottom: '1px solid #d5d8db' }}
          >
            <h2>Superadmin Step Types</h2>
          </div>
          <div className="row">
            <div className="d-flex flex-wrap">
              <NewStepCard
                newAssessment={newAssessment}
                authorized={authorizations.can_create_adaptive}
                title="Third Party Marketplace Assessment"
                description="Re-direct to a third party assessment provider"
                stepType="third_party"
                icon="call_split"
                onClick={onClick}
                disabled={disabled}
              />

              <NewStepCard
                newAssessment={newAssessment}
                authorized={authorizations.can_create_adaptive}
                title="Adaptive Form"
                description="Questions increase or decrease in difficulty depending on the candidate's ability."
                stepType="adaptive_form"
                icon="show_chart"
                onClick={onClick}
                disabled={disabled}
              />

              <NewStepCard
                newAssessment={newAssessment}
                title="Typing Test v2"
                description="Questions increase or decrease in difficulty depending on the candidate's ability."
                stepType="typing_test_v2"
                icon="keyboard"
                onClick={onClick}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </SuperAdmin>
    </div>
  );
};

export default NewStepCards;
