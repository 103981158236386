import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  object,
  string,
  number,
  oneOfType,
  func,
  bool,
  array,
} from 'prop-types';
import {
  change,
  registerField,
  removeField,
  clearValidationErrors,
} from './actions';

const getPath = props => `${props.form.name}.${props.id}`;

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: value => {
      dispatch(change(getPath(ownProps), value));
    },
    registerField: (value = '') =>
      dispatch(registerField(getPath(ownProps), value)),
    removeField: () => dispatch(removeField(getPath(ownProps))),
    clearValidationErrors: () =>
      dispatch(clearValidationErrors(ownProps.id, ownProps.form.name)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const value = get(state, `form.${getPath(ownProps)}`);
  return {
    value,
  };
};

export class Field extends Component {
  static propTypes = {
    initialValue: oneOfType([bool, string, number, array]),
    registerField: func.isRequired,
    serialize: func,
    validate: func,
    removeField: func.isRequired,
    value: oneOfType([bool, string, number, array]),
    onChange: func.isRequired,
    required: bool,
    onSubmit: func,
  };

  static defaultProps = {
    initialValue: '',
    required: false,
  };

  UNSAFE_componentWillMount() {
    this.props.registerField(this.props.initialValue);
    if (this.props.serialize) {
      this.props.form.registerSerializer(this.props.id, this.props.serialize);
    }
    if (this.props.validate) {
      this.props.form.registerValidator(this.props.id, this.props.validate);
    }
    if (this.props.required) {
      this.registerRequiredValidator();
    }
    if (this.props.onSubmit) {
      this.props.form.registerFieldOnSubmitCallback(
        this.props.id,
        this.props.onSubmit,
      );
    }
  }

  componentWillUnmount() {
    this.props.form.unregisterFieldOnSubmitCallback(this.props.id);
    this.props.removeField();
  }

  getValue = () => {
    const { value, initialValue } = this.props;
    // If initial value hasn't been registered yet
    if (value === undefined) {
      return initialValue || '';
    }
    return value;
  };

  registerRequiredValidator = () => {
    const { id, form, initialValue } = this.props;
    let validator = value => (value ? false : form.requiredError);
    if (initialValue) {
      validator = value => {
        return value && JSON.stringify(value) !== JSON.stringify(initialValue)
          ? false
          : form.requiredError;
      };
    }
    form.registerValidator(id, validator);
  };

  registerOnSubmit = callback => {
    return this.props.form.registerFieldOnSubmitCallback(
      this.props.id,
      callback,
    );
  };

  clearErrors = value => {
    let isValid = true;
    if (this.props.validate) {
      const error = this.props.validate(value);
      isValid = !error;
    }
    if (isValid) {
      this.props.clearValidationErrors();
    }
  };

  handleChange = e => {
    const value = get(e, 'target.value', e);
    this.props.onChange(value);
    this.clearErrors(value);
  };

  render() {
    const renderProps = {
      registerOnSubmit: this.registerOnSubmit,
    };
    const value = this.getValue();
    return this.props.children(this.handleChange, value, renderProps);
  }
}

const FieldWrapper = connect(mapStateToProps, mapDispatchToProps)(Field);

FieldWrapper.propTypes = {
  form: object.isRequired,
  id: oneOfType([number, string]).isRequired,
};

export default FieldWrapper;
